* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

}

.selectMonth select {
    width: 164px;
    height: 46px;
    padding: 10px;
    font-size: 18px;
    border: 0.5px solid #424242;
    border-radius: 10px;
    transition: border-color 0.3s ease;
}

.selectMonth select:focus {
    border-color: #000; /* Change this to the desired color when focused */
}

.selectMonth select:valid {
    border-color: #000; /* Change this to the desired color when a month is selected */
}

.selectMonth select:active {
    border: 1px solid #000;
}

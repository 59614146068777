/* PostDetail.css */
@import url('https://gistcdn.githack.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css');

.post-detail {
    background-color: #ececec;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 0;
    margin-bottom: 16px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*.post-detail iframe{*/
/*    display: flex;*/
/*    !*width: 100%;*!*/
/*    !*height: 100%;*!*/
/*    align-self: center;*/
/*    justify-content: center;*/
/*    margin-top: 5vh;*/
/*    margin-bottom: 5vh;*/
/*}*/
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.post-image-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 5vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-bottom: 15vh;*/
}

.postdetail-image {
    width: 70vw;
    height: auto;
    display: block;
}


.post-meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
}

.post-comments {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.author-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
}

.author-name {
    font-size: 16px;
    color: #888;
    font-weight: 400;
}

.searchBarEach {
    display: flex;
    align-self: flex-end;
    margin-right: 10%;
    width: 25%;
    min-width: 300px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    outline: none;
    margin-top: 10%;
    border-radius: 50px;
    border-color: #ddd;
    padding-left: 15px;
    padding-right: 15px;
}

.searchBarEach:focus {
    border-color: black;
    outline: none;
}

.post-date {
    display: flex;
    align-items: center;
    color: #424242;
    font-size: 14px;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.date-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: white;
}

.date-text {
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 450;
}

.post-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    transition: color 0.7s;
}

.post-title a {
    color: #333;
    text-decoration: none;
}

.post-title a:hover {
    color: #333;
}

.post-content {
    background-color: #FFFFFF;
    margin-top: 5vh;
    width: 80vw;
    min-width: 300px;
    padding-bottom: 5vh;
    text-align: justify;
    display: flex;
    flex-direction: column;
    color: #424242;
    /*font-family: 'Gilroy', sans-serif;*/
    font-family: Helvetica Neue, Arial, sans-serif;
    align-items: center;
}

.post-content h1 {
    font-size: 47px;
    text-align: center;
    color: grey;
    margin-bottom: 10%;
    margin-top: 6%;
    font-weight: 450;

}

.post-content h2 {
    font-size: 35px;
    margin-bottom: 2%;
    margin-top: 5%;
    color: #424242;
    text-align: left;
    font-weight: 450;

}

.post-content h3 {
    text-align: left;
    font-size: 25px;
    margin-bottom: 1%;
    margin-top: 3%;
    color: #575757;
    font-weight: 500;

}

.post-content p {
    text-align: left;
    font-size: 20px;
    margin-top: 1%;
    margin-bottom: 1%;
}

.blog-content {
    display: flex;
    position: relative;
    /*left:20%;*/
    flex-direction: column;
    font-size: 18px;
    margin-top: 5%;
    margin-bottom: 1%;
    width: 60%;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.blog-content a {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #167ac6
}

.category-block {
    display: flex;
    font-size: 16px;
}

.scroll-to-top {
    align-self: flex-end;
    margin-top: 2%;
    background-color: transparent;
    color: #6b6666;
    font-size: 16px;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding-right: 20px;
}

.scroll-to-top:hover {
    background-color: transparent;
    color: #424242;
}

/* Define your other styles here */
@media (max-width: 450px) {
    .post-image-container {
        position: relative;
        overflow: hidden;
        padding-bottom: 5vh;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        /*margin-bottom: 15vh;*/
    }

    .postdetail-image {
        width: 95vw;
        height: auto;
        display: block;
    }

    .blog-content {
        display: flex;
        position: relative;
        /*left:20%;*/
        flex-direction: column;
        font-size: 18px;
        margin-top: 10%;
        margin-bottom: 1%;
        width: 90%;
    }

    .searchBarEach {
        display: flex;
        justify-content: center;
        width: 25%;
        min-width: 300px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        outline: none;
        margin-top: 10%;
        border-radius: 50px;
        border-color: #ddd;
    }

    .searchBarEach:focus {
        border-color: black;
        outline: none;
    }

    .post-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 16px;
        width: 100%;
    }
}


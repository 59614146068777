* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* width: 900px; */
    overflow-x: hidden;
}

.contacting {
    display: flex;
    font-size: 22px;
}

.contact {
    margin: 200px;
}

.backBtn {
    background-color: #fff;
    border: 2px solid #00000081;
    width: 101px;
    padding: 8px 6px 8px 0px;
    /* padding: 10px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 200px;
    top: 100px;
    margin-top: 20px;
}

.backBtn a {
    text-decoration: none;
    color: #000;
}

.backicon {
    width: 30px;
    padding-top: 3px;
    display: inline;
}

@media (max-width: 450px) {
    .contact {
        margin: 97px 10px 10px 47px;
    }

    .backBtn {
        background-color: #fff;
        border: 2px solid #00000081;
        width: 101px;
        padding: 8px 6px 8px 0px;
        /* padding: 10px; */
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 51px;
        top: 12px;
    }
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow-y: hidden; */
}

.aboutus-webpage {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
}

.blankie {
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
}

.aboutContent {
    /*padding-top: 40vh;*/
    width: 95vw;
    padding-top: 20px;
    text-align: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
    /*height: 82vh;*/
}

.enquirydiv span {
    width: 95vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
}

.aboutContent p {
    padding: 10px;
    font-size: 21px;
    /* color: #424242; */
    color: #424242;
    font-weight: 300;
    text-align: start;
    /* color: #1C98ED; */
    line-height: 80px;
    /* font-weight: 500; */
}

.aboutline {
    border: 1px solid #000;
}


.lineWrapper {
    position: relative;
}


.lineWrapper p {
    margin: 0;
    padding: 10px 0;
}

.spacie {
    width: 100%;
    height: 250px;
}

.socialmediaIcons {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 4px;
    font-size: 20px;
}

.footersocialabout {
    display: flex;
    align-items: center;
    width: 380px;
    justify-content: flex-start;
    padding: 41px 49px 10px 22px;
}

.footersocialabout ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-bottom: 20px;
}
.footersocialabout ul li {
    list-style: none;
    font-size: 40px;
}

.enquirydiv span {
    font-size: 21px;
    color: #424242;
    font-weight: 300;
    text-align: left;
    align-self: flex-start;
    justify-content: left;
    width: auto;
}

.enquirydiv {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    gap: 10px;
    margin-top: 100px;
    text-align: left;
}

@media (max-width: 819px) {
    .aboutContent {
        padding-top: 40vh;
        width: 95vw;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin: 0 auto; */
        height: 82vh;
    }

    .enquirydiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        margin-top: 100px;
        text-align: left;
    }
}

@media (max-width: 450px) {
    .blankie {
        height: 0px;
        width: 100%;
    }

    .aboutContent {
        /*width: 90vw;*/
        padding-top: 20vh;
        height: auto;
        text-align: center;
        margin: 0 0 0 13px;
        /* border: 3px solid rgba(243, 243, 243, 0.865); */
    }

    .aboutContent p {
        /*width: 90vw;*/
        font-size: 20px;
        padding: 5px;
        /* color: #424242; */
        color: #424242;
        /* color: #1C98ED; */
        line-height: 51px;
        font-weight: 300;
    }

    .aboutline {
        border: 1px solid #000;
    }

    .lineWrapper {
        position: relative;
    }

    .lineWrapper p {
        margin: 0;
        /*width: 90vw;*/
        padding: 9px;
    }

    .spacie {
        width: 100%;
        height: 100px;
    }

    .socialmediaIcons {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 4px;
        font-size: 0px;
        width: 450px;
    }

    .socialmediaIcons p {
        line-height: 15px;
        font-size: 20px;
        width: 191px;
        font-weight: 400;
    }

    .footersocialabout {
        display: flex;
        align-items: center;
        width: 426px;
        justify-content: flex-start;
        padding: 41px 49px 10px 0px;
    }

    .footersocialabout ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding-bottom: 30px;
    }

    .enquirydiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        margin-top: 100px;
        text-align: left;
    }

    .enquirydiv span {
        display: flex;
        font-size: 19px;
        color: #424242;
        align-items: flex-start;
        text-align: left;
        /*padding-left: 14px;*/
    }
}

/* .enquiry{
    color: #000;

  } */

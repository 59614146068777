.post-header {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03); /* horizontal-offset vertical-offset blur-radius shadow-color */
    position: fixed;
    z-index: 1500;
    background: #FFFFFF;
    top: 0;
}

.post-header-container {
    /*position: fixed;*/
    /*z-index: 1500;*/
    /*top:0;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    flex: 1;
    max-width: 1400px;
}

.post-head-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.post-header-img-logo {
    width: 14vw;
    max-width: 250px;
    min-width: 150px;;
    height: auto;
}

.post-head-logo {
    margin-left: 30px;
    margin-right: 2%;
    margin-top: 0;
    padding-top: 0;
}

.head-home li {
    margin-left: 50px;
    list-style: none;
    cursor: pointer;
}

.head-home a {
    color: rgba(2, 2, 2, .679);
    font-size: 25px;
    text-decoration: none;
}

.head-profile {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}
.home-and-discover {
    display:flex;
    justify-content: space-between;
     width:31vw;
}

@media (max-width: 500px) {
    .head-home {
        display: none;
    }

    .post-header-container {
        background: #FFFFFF;
        z-index: 100;
    }
    .home-and-discover {
        display:flex;
        justify-content: space-between;
        width:15vw;
    }
}

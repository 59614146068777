.butt button {
    box-shadow: inset 0px 1px 0px 0px #80abe5;
    background: linear-gradient(to bottom, #3383ea 5%, #217cf4 100%);
    background-color: #042c60;
    border-radius: 11px;
    border: 2px solid #4789e0;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 18px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #377ad4;
}

.loading-animation {
    display: flex;
    flex: 1;
    justify-content: center;
    vertical-align: center;
    align-items: center;
    align-self: center;
    background-color: #FFFFFF;
    flex-direction: column;
}

.popup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    width: auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    align-items: center;
    display: flex;
    flex-direction: column;
}

.popup p {
    margin-bottom: 25px;
    color: #be3333;
}

.popup button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.popup button:hover {
    background: linear-gradient(to bottom, #5fa1f7 5%, #4a91ee 100%);
    background-color: #004AAD;
}

.mainMessage {
    display: flex;
    color: #525050;
    align-self: center;
}

.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 0.5px solid #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
    background: rgba(238, 238, 238, 0.43);
    border-color: #111;
}

.drop-container:hover .drop-title {
    color: #222;
}

.drop-container.drag-active {
    background: #eee;
    border-color: #111;
}

.drop-container.drag-active .drop-title {
    color: #222;
}

.drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: rgba(232, 229, 229, 0.45);
    padding: 2% 20px;
    border-radius: 10px;
    /*color: #fff;*/
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: rgba(211, 211, 211, 0.64);
}

input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 0.5px solid #555;
}

.uploadContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listexpenseBank {
    position: relative;
    display: flex;
    padding-bottom: 20px;
    cursor: pointer;
    align-self: center;
    left: -4%;
}

.bankcount {
    /*padding: 53px 47px;*/
    padding-right: 47px;
    padding-top: 53px;

}

.bankcount p {
    background: #ffffff;
    color: #000;
    width: 44px;
    font-size: 21px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    border-radius: 50%;
}

.drop-message {
    text-align: center;
    color: darkred;
}


.bottomSpace {
    padding-bottom: 350px;
}

.transactionpage {
    margin-top: 10vh;
}

.butt button {
    margin-top: 20px;
}

.butt button:hover {
    background: linear-gradient(to bottom, #5fa1f7 5%, #4a91ee 100%);
    background-color: #004AAD;
}

.butt button:active {
    position: relative;
    top: 1px;
}

.massive {
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    padding-top: 0px;
    /* position: absolute; */
    /* right: 0; */
    /* display: flex; */
}

.statement-selecter-all {
    position: relative;
    display: flex;
    flex-direction: column;
    /*left: -10%;*/
    /*gap: 65px;*/
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.monthDta {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    /*width: 663px;*/
}

.massive p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 20px;
}

.massive span {
    padding-left: 15px;
    color: #004AAD;
}

.titlesBank {
    color: #004AAD;
    font-weight: 600;
}

.mainpathBank {
    position: relative;
    display: flex;
    flex-direction: column;
    /*left: -10%;*/
    /*gap: 65px;*/
    padding-top: 45px;
    padding-bottom: 100px;
}

.insightarraow {
    display: block;
    position: absolute;
    right: 0;
    top: 32vh;
    font-size: 25px;
    left: 79vw;
}

.arraows {
    width: 87px;
    color: #000;
}

.arraows li a {
    text-decoration: none;
    list-style: none;
}

.insightarraow a {
    text-decoration: none;
    color: #004AAD;
}

.selectBank {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

.selectBank form label {
    color: #004AAD;
    font-size: 20px;
    /* padding: 10px; */
}

.selectBank form select {
    width: 135px;
    height: 33px;
    font-size: 16px;
    border-radius: 5px;
    margin: 10px 0px 10px 5px;

}

.selectBank form select option {
    font-size: 15px;
    padding: 5px;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }
}

.shake {
    animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.modal-password {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 5000;
}

.modal-body-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    width: 320px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    z-index: 5001;
}

.modal-body-password h2 {
    margin-bottom: 15px;
}

.modal-body-password p {
    /*margin-bottom: 5px;*/
    text-align: center;
    color: #333333;
    font-size: 16px;
}

.modal-body-password label {
    align-self: flex-start;
    margin-bottom: 5px;
    color: #555555;
    font-size: 14px;
    margin-top: 20px;

}

.modal-body-password input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
}

.modal-body-password button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4285F4;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
}

.modal-body-password button:disabled {
    background-color: #cccccc;
}

.modal-body-password button:not(:last-child) {
    margin-right: 10px;
}

/*@media (max-width: 412px) and (max-height: 803px) {*/
/*    .transactionpage{*/
/*        margin-top: 100px;*/
/*        width: 500px;*/
/*        !*margin-left: 100px;*!*/
/*        !*align-content: center;*!*/
/*    }*/
/*}*/
/* Your existing CSS code */

/*@media (max-width: 450px)  {*/
/*    .transactionpage {*/
/*        margin-top: 50px;*/
/*        width: 500px;*/
/*        padding: 0 10px;*/
/*    }*/

/*    .insightarraow {*/
/*        top: 150px;*/
/*        left: calc(100% - 40px);*/
/*        font-size: 18px;*/
/*    }*/

/*    .selectBank {*/
/*        margin-top: 20px;*/
/*    }*/

/*    !* Adjust header styles *!*/
/*    header {*/
/*        position: relative;*/
/*        top: 0;*/
/*        left: 0;*/
/*        width: 100%;*/
/*    }*/
/*}*/

/* PostCard.css */

/* PostCard.css */

/* PostCard.css */

.post-card {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
    border-radius: 8px;
    padding: 0;
    margin-bottom: 16px;
    width: 99%; /* Adjust the width as needed */
    height: auto;
    /*margin-right: 400%;*/
    justify-content: center;
}

/* Rest of your CSS remains the same */


.image-container {
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #b1b6bc;
    height: auto;
    width: 90%;
    margin-top: 5vh;
}

.post-image {
    width: 100%;
    height: auto;
    display: flex;
    border: rgba(188, 188, 199, 0.54);
    border: 0.3px solid rgba(218, 208, 208, 0.47);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

}

.post-title {
    display: flex;
    font-size: 24px;
    /*font-weight: bold;*/
    text-align: center;
    justify-content: center;
    align-content: center;
    transition: color 0.7s;

}

.post-title a {
    color: #333;
    text-decoration: none;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 2.5rem;
    margin-bottom: 2vh;

}

.post-title a:hover {
    color: #004AAD;
}

.post-author {
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-top: 30vh;*/
    /*margin-right: 30vw;*/
}

/*.post-date {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 1vh;*/
/*    margin-left: 5vw;*/
/*}*/

.author-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /*margin-right: 8px;*/
}

.author-name {
    font-size: 16px;
    color: #424242;
    font-weight: 450;
}

.post-date {
    display: flex;
    align-items: center;
    color: #888;
    /*font-size: 16px;*/
    margin-bottom: 8px;
}


.date-text {
    font-weight: 600;
    color: #888888;
    /*margin-left: 5vw;*/
}

.excerpt-post p {
    font-size: 21px;
    color: rgb(150, 150, 150);
}

.post-excerpt {
    font-size: 16px;
    text-align: center;
    color: #424242;
    margin: 16px 0;
    font-weight: 300;
}

.read-more {
    display: flex;
    text-align: center;
    margin-bottom: 16px;
    align-self: center;
    vertical-align: center;
}

.read-more a {
    background-color: #004AAD;
    color: #fff;
    padding: 8px 16px;
    border-radius: 24px;
    text-decoration: none;
    /*font-weight: ;*/
    font-size: 12px;
    transition: background-color 0.7s;

}

.read-more a:hover {
    background-color: #004AAD;
}

.post-title-container {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
    transition: color 0.7s;
    margin-top: 30vh;
    margin-left: 3vw;
    width: 30%;
}

.post-title-container a {
    color: #333;
    text-decoration: none;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 2rem;
}

.image-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*margin-top: 15vh;*/
}

.excerpt-post {
    text-align: center;
    align-self: center;
    width: 60%;
}

.commentsLength {
    margin-left: 3vw;
    flex-direction: row;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    justify-content: center;
}

@media (max-width: 600px) {
    .excerpt-post {
        text-align: center;
        align-self: center;
        width: 100%;
    }
}

@media (max-width: 450px) {
    .excerpt-post {
        text-align: center;
        align-self: center;
        width: 100%;
    }
}

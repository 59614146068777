{
    margin: 0px
;
    padding: 0px
;
    box-sizing: border-box
;
}

body {
    font-family: 'Lato', sans-serif;
}

html {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogsContainer {
    /*display: flex;*/
    width: 100vw;
    background-color: #F7F7F7;
    /*flex-direction: row;*/
}

.blogsContainerInside {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 10%;
    margin-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
}


.blogs {
    /*margin-top: 10vh;*/
    /*top: 0;*/
    margin-bottom: 1vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /*margin-left: 35vh;*/
    gap: 5%;
    padding: 5%;
    width: 80vw;
    height: auto;
}

.postWidget {
    /*margin-top: 10vh;*/
    /*top: 0;*/
    margin-bottom: 1vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /*margin-left: 35vh;*/
    /*gap: 5%;*/
    padding: 5%;
    width: 200vw;
    height: auto;
    flex: 1;
}

.blogs p {
    font-size: 20px;
    color: rgb(150, 150, 150);
}

.searchBar {
    display: flex;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /*margin-bottom: 3px;*/
    width: 30%;
    /*max-width: 400px; !* Adjust the max-width as needed *!*/
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    outline: none;
    margin-top: 5vh;
    margin-left: 50vw;

}

/* Adjust the search button styles as needed */
.searchBar:focus {
    border-color: black;
    outline: none;
}

.featuredPost {
    margin-bottom: 1vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /*margin-left: 35vh;*/
    /*gap: 5%;*/
    padding: 5%;
    width: 200vw;
    height: auto;
    flex: 1;
}

.copyright-blog {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: #8d8d8d;
    align-self: flex-start;
}

.viewAllBlogs a {
    text-decoration: none;
    color: #333;
    font-weight: 400;
}

.viewAllBlogs a:hover {
    text-decoration: underline;
}

.blog_loading_animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40vh;
}

.complete-blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
}

.complete-blog h1 {
    text-align: center;
    font-weight: 400;
    color: #424242;
}

.blog-editor-container {
    width: 100vw;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row-reverse;
}

.blog-form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 20px;
}

.blog-form textarea {
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    align-content: center;
    font-size: 15px;
    padding-left: 10px;
    padding-top: 10px;
    border-width: 0.5px;
}


.editor-title {
    text-align: center;
}

.editor-toolbar {
    margin-bottom: 10px;
}
.editor-insert{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.toolbar-button {
    background-color: #0004AD;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    width: 100%;
}

.editor {
    display: flex;
    height: 45rem;
    flex-direction: column;
    padding: 10px;
}

.post-button {
    background-color: #0004AD;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
}

button .ql-header {
    color: #0e0e0f;
    background-color: #0e0e0f;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.services div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }

.demo-header{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 100vw;
    background-color: #badcf3;
    /* margin-top: 20vh; */
}
.demo-header-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 70vw;
    padding-top: 10px;
    padding-bottom: 10px;
}
.demo-header-content p{
    color: #424242;
    text-align: left;
    font-size: 17px;
}
.servicesContainer {
    display: flex;
    justify-content: center;
    flex:1;
    flex-direction: column;
}
.topContainer {
    display: flex;
    flex-direction: column;
}
.try-now{
    background-color: #92c8ec;
    color: #004AAD;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    cursor:pointer;
    min-width: 140px
}

.services {
    display: grid ;
    width: 100%;
    /* height: 90vh; */
    /*position: absolute;*/
    /*left: 77px;*/
    /*top: 230px;*/
    align-items: center;
    padding-top: 30px;
    align-self: center;
    align-content: center;
    justify-content: center;
    gap: 40px;
    text-align: center;
    /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;*/
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* background-color: tomato; */
}

.services img {
    width: 100px;
    height: 100px;
}

.services p {
    font-size: 21px;
    padding-top: 5px;
    font-weight: 500;
}
.TFP_Services{
    border: 2px solid #1C98ED;
    border-radius: 10px;
    width: 192px;
    height: 182px;
    padding: 3px;
}

.TFP_Services p{
    font-size: 17px;
}
.chartered_account{
    border: 2px solid #1C98ED;
    border-radius: 10px;
    width: 192px;
    height: 182px;
    padding: 0px;
}
.chartered_account p {
    font-size: 15px;
}

.services a {
    text-decoration: none;
    color: #000;
}

.transaction,
.spending,
.budget {
    border: 2px solid #1C98ED;
    border-radius: 10px;
    width: 192px;
    height: 182px;
    padding: 3px;
}

.trackBtnMob {
    display: none;
}

.transaction:hover,
.spending:hover,
.budget:hover {
    background-color: #1c99ed98;
}

/* .transaction li{
    width: 50px;
    height: 50px;
} */
.transaction p {
    padding: 20px;
}

.spending p {
    padding: 6px;
}

.budget p {
    padding: 0px;
    margin-top: 0;
}

.transaction img {
    padding: 10px;
}

.trackBtn {
    padding: 15px;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 300px;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #0000;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 370px 600px 0px 600px;
    border: 1px solid #000;
    color: #131010;
    border-radius: 10px;
}

.trackBtn:hover {
    background-color: #d5d3d3a6;
    color: #000000;
}

.arrow {
    margin-top: 450px;
    display: inline;
}

.arrowimg {
    /* top: 517px;
    left: 526px; */
    width: 175px;
    height: 100px;
    position: relative;
    top: 487px;
    left: 412px;
    color: #023f8f;
}

.googlesigntryforfree {
    margin: 20px 47% 20px 598px;
    text-align: center;
    padding-top: 9px;
    display: flex;
    width: 300px;
    border-radius: 5px;
    align-items: center;
    background: #fff;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgb(43, 43, 43);
}

@media (max-width: 1400px) {
    .demo-header-content{
    
        width: 90vw;
    }
}
@media (max-width: 450px) {
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 90%;
        align-items: flex-start;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap:30px;
        left: 10px;
        top: 10px;
    }
    .services div {
        width: 120px;
        height: 130px;
    }
    /* .TFP_Services{
        border: 2px solid #1C98ED;
        border-radius: 10px;
        width: 118px;
        height: 106px;
        padding: 2px;
        margin: 5px;
        overflow: hidden;
    } */
    .chartered_account {

        border: 2px solid #1C98ED;
        border-radius: 10px;
        width: 100px;
        height: 106px;
        padding: 2px;
        /* margin: 5px; */
        overflow: hidden;
    }
    .services-custom img {
        width: 50px;
        height: 50px;
    }

    .services-custom p {
        font-size: 11px;
        padding-top: 12px;
        font-weight: 500;
    }

    .services-custom a {
        text-decoration: none;
        color: #000;
    }

    /* .services-custom img {
        width: 65px;
        height: 60px;
        margin-top: 8px;
    } */
    .transaction,
    .spending,
    .budget,
    .TFP_Services {
        border: 2px solid #1C98ED;
        border-radius: 10px;
        width: 100px;
        height: 106px;
        /* padding: 2px; */
        margin-right: 20px;
        overflow: hidden;
    }
    

    .transaction:hover,
    .spending:hover,
    .budget:hover {
        background-color: #1c99ed98;
    }

    /* .transaction li{
        width: 50px;
        height: 50px;
    } */
    .transaction p {
        padding: 9px;
    }

    .spending p {
        padding: 6px;
    }

    .budget p {
        padding: 0px;
    }
    .TFP_Services p {
        padding: 0px;
    }
    .transaction img {
        padding: 2px;
    }

    .trackBtnMob {
        /* padding: 0px; */
        display: flex;
        flex-direction: row;
        gap: 10px;
        /* font-size: 20px;
        font-weight: 600;
        width: 300px;
        text-align: center;
        top: 574px;
        left: 0;
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #0000;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        border: 1px solid #000;
        color: #131010;
        border-radius: 10px; */
        position: absolute;
        top: 600px;
        left: 100px;
        width: 214px;
        /* background-color: #d8d6d6; */
        align-items: center;
        justify-content: center;
        padding: 5px;
        border: 1px solid #818080b0;
        border-radius: 10px;
    }

    .trackBtnMob p {
        padding-bottom: 12px;
        color: #5a5858;
    }

    .trackBtnMob:hover {
        background-color: #d5d3d3a6;
        color: #000000;
    }

    .track {
        margin-bottom: 300px;
    }

    .arrowmob {
        /* position: absolute;
        top: 500px;
        left: 0; */
        margin-top: 450px;
        display: contents;
    }

    .arrowimgmob {
        width: 65px;
        height: 16px;
        position: absolute;
        top: 630px;
        left: 33px;
        color: #023f8f;
        padding-right: 7px;
    }

    .googlesigntryforfree {
        margin: 20px 47% 20px 598px;
        text-align: center;
        padding-top: 9px;
        display: flex;
        width: 300px;
        border-radius: 5px;
        align-items: center;
        background: #fff;
        justify-content: center;
        cursor: pointer;
        border: 1px solid rgb(43, 43, 43);
    }

}
@media (min-width: 450px) and (max-width: 769px) {
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr ;
        left: 10px;
        top: 10px;
        padding-top: 134px;
    }
    .services-custom div{
        width: 180px;
        height: 180px;
    }
    .services img {
        width: 35%;
        height: 60px;
    }
    .services p {
        font-size: 16px;
    }
    .demo-header-content{
    
        width: 0vw;
    }
   

}
@media (min-width: 769px) and (max-width: 1024px) {
    .servicesContainer {
        padding-top: 10vh;
    }
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr ;
        grid-template-rows: 1fr 1fr ;
        left: 10px;
        top: 10px;
    }
    .services-custom div{
        width: 200px;
        height: 200px;
    }
    .TFP_Services{
        border: 2px solid #1C98ED;
        border-radius: 10px;
        width: 118px;
        height: 106px;
        padding: 2px;
        margin: 5px;
        overflow: hidden;
    }
   

}
@media (min-width: 1024px) and (max-width: 1680px) {
    .servicesContainer {
        padding-top: 10vh;
    }
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr ;
        left: 10px;
        top: 10px;
    }
    .services-custom div{
        width: 200px;
        height: 200px;
    }
}
@media (min-width:1680px) and (max-width: 2250px) {
    .servicesContainer {
        padding-top: 10vh;
    }
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 100%;
        height: auto;
        align-items: center;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr ;
        left: 10px;
        top: 10px;

    }
    .services-custom div{
        width: 300px;
        height: 300px;
    }
    .services img {
        width: 55%;
        height: 145px;
     }
     .services p {
         font-size: 25px;
         padding-top: 15px;
         font-weight: 500;
     }
}
@media (min-width: 2250px) {
    .servicesContainer {
        padding-top: 10vh;
    }
    .services-custom {
        /* display: block;
        width: auto;
        position: relative;
        left: 10px;
        top: 10px;
        padding-top: 200px;
        padding-bottom: 20px; */
        display: grid;
        width: 100%;
        height: 50%;
        align-items: center;
        margin: 0 auto;
        /*margin-left: 10%;*/
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr ;
        left: 10px;
        top: 10px;
    }
    .services-custom div{
        width: 400px;
        height: 400px;
    }
    .services img {
        width: 45%;
        height: 180px;
        padding: 5px;
     }
     .services p {
         font-size: 31px;
         padding-top: 15px;
         font-weight: 500;
     }
}


.business_page {
    width: 100vw;
}

.coming_soon {
    display: flex;
    flex: 1;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
    flex:1;
    height: 100vh;
}
.coming_soon p {
    font-size: 20px;
    color: #676767;
    max-width: 400px;
    text-align: center;
}
.coming_soon h1 {
    font-size: 50px;
    color: #676767;
    text-align: center;
    padding-bottom: 10px;
}
.coming_soon button {
    background-color: #004AAD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 20px;
}
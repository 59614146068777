* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.center-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}

.isInfoVisibleTransactions {
    padding: 10px;
    position: absolute;
    top: 15vh;
    left: 68vw;
    height: auto;
    border-radius: 8px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
    min-width: 100px;
    max-width: 300px;
    contain: content;
}

.isInfoVisibleTransactions.visible {
    display: block;
}

.isInfoVisibleTransactions.visible {
    flex-direction: column;
    font-size: 18px;
    color: #000;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 15px;
    font-weight: 300;
}

.isInfoVisibleTransactions {
    font-size: 16px;
    color: #000;
}

.appleimage {
    max-width: 15vw;
    object-fit: contain;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: rgba(241, 241, 241, 0.49); */
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    /* border: rgba(241, 241, 241, 0.49); */
    /* border-color: rgba(241, 241, 241, 0.49); */
    /* border-width: 5px; */
    /* border-style: solid; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    max-width: 90vw;
}

.title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}

.description {
    font-size: 20px;
    margin-bottom: 30px;
    max-width: 35vw;
    display: flex;
    justify-content: center;
    align-self: center;
}

.download-links {
    display: flex;
    justify-content: center;
}

.download-link {
    margin: 0 10px;
}

@media (max-width: 600px) {
    .title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .download-links {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .appleimage {
        max-width: 20vw;
        object-fit: contain;
    }
}

body {
    height: fit-content;
}

.bottomSpace {
    height: 350px;
}

.Heading {
    text-align: center;
    margin-top: 90px;
}

.Heading p {
    font-size: 32px;
    font-weight: 600;
}

.topcategory {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

.topcategory img {
    width: 140px;
    height: 119px;
}

.addtxt {
    cursor: pointer;

}

.topcategory p {
    font-size: 25px;
    color: #000;
}

/* .subheading  {
    display: block;
} */
/* .subheading p {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    color: #004AAD;
    margin-top: 50px;
} */
.subhead {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    color: #004AAD;
    margin-top: 50px;
}

/* .expense {
    width: 401px;
    height: 128px;
    background: rgba(217, 217, 217, 0.12);
    border: 1px solid #000000;
    border-radius: 1px;
} */

.listexpense {
    display: flex;
    padding-bottom: 20px;
    cursor: pointer;
}

.mainpath {
    position: absolute;
    left: 28%;
    gap: 65px;
    padding-top: 45px;
    padding-bottom: 100px;
}

.count {
    padding: 53px 47px;
    /*padding-right: 47px;*/
    /*padding-top: 53px;*/

}

.count p {
    background: #ffffff;
    color: #000;
    width: 44px;
    font-size: 21px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    border-radius: 50%;
}

.subheading span {
    font-weight: 600;
    /* color: rgb(232, 56, 56); */
}

.expensenonnotes {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: center;
    height: 128px;
    background: rgba(217, 217, 217, 0.12);
    border: 1px solid #000000;
    border-radius: 1px;
    width: 401px;
    margin-bottom: 20px;
}

.expense {
    /* display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: center; */
    width: 401px;
    /* height: 128px; */
    /*height: 193px;*/
    background: rgba(217, 217, 217, 0.12);
    border: 1px solid #000000;
    border-radius: 1px;
}

.innerExpense {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: center;
}

.date {
    text-align: center;
}

.merchant {

    padding-top: 46px;
}

.merchantnonnotes {
    padding-top: 13px;
}

.price {
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.price span {
    padding-top: 5px;
    font-weight: 300;
    font-size: 20px;
}

.merchantnonnotes p {
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.merchant p {
    font-size: 20px;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.bottommerchant {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

/* .shopIcon{

} */
.shopIcon img {
    width: 37px;
    height: 42px;
    padding-top: 6px;
}

.shop {
    color: #004AAD;
}

.shopEnter {
    color: #e60c1b;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* .shopie {
    color: #d40000;
} */
.warning {
    color: #e60c1be0;
    width: 21px;
    height: 31px;
}

.retry {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 56px;
    left: 159px;

}


.retry a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.retry img {
    width: 24px;
    padding-top: 5px;
    height: 28px;
}

.entercat {
    text-align: center;
    padding-top: 7px;
}

.entercat a {
    text-decoration: none;
    color: rgb(220, 16, 16);
}

.noting p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /* background-color: #aebdd2; */
}

.notetag {
    width: 50px;
    height: 30px;
    color: #b0b0b08d;
    margin-bottom: 20px;
}

.alignnote {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 179px;
    /*margin-top: 10px;*/
}

.transactionpage {
    margin-top: 100px;
    width: 1200px;
}

/* .editoverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
  } */


.Spendinsightarraow {
    display: block;
    position: absolute;
    right: 0;
    top: 147px;
    font-size: 25px;
    left: 1136px;
}

.erow {
    width: 87px;
    color: #000;
}

.erow li a {
    text-decoration: none;
    list-style: none;
}

.Spendinsightarraow a {
    text-decoration: none;
    color: #004AAD;
}


/*@media (max-width: 1920px) {*/

/*    .topcategory p {*/
/*        font-size: 25px;*/
/*        color: #000;*/

/*    }*/

/*    .topcategory img {*/
/*        width: 130px;*/
/*        height: 119px;*/
/*        margin-right: 70px;*/
/*    }*/

/*    .Heading p {*/
/*        font-size: 32px;*/
/*        font-weight: 600;*/
/*        margin-right: 60px;*/
/*    }*/

/*    .subhead {*/
/*        text-align: center;*/
/*        font-size: 28px;*/
/*        font-weight: 300;*/
/*        color: #004AAD;*/
/*        margin-top: 50px;*/
/*        margin-right: 60px;*/
/*    }*/
/*    .expensenonnotes {*/
/*        display: grid;*/
/*        grid-template-columns: 1fr 2fr 1fr;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 128px;*/
/*        background: rgba(217, 217, 217, 0.12);*/
/*        border: 1px solid #000000;*/
/*        border-radius: 1px;*/
/*        width: 401px;*/
/*    }*/

/*    .count p {*/
/*        background: #ffffff;*/
/*        color: #000;*/
/*        width: 44px;*/
/*        font-size: 21px;*/
/*        border: 1px solid #000;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 43px;*/
/*        border-radius: 50%;*/
/*    }*/

/*    .mainpath {*/
/*        position: absolute;*/
/*        left: 31%;*/
/*        gap: 65px;*/
/*        padding-top: 45px;*/
/*        padding-bottom: 100px;*/
/*    }*/

/*    .shopEnter {*/
/*        color: #e60c1b;*/
/*        padding-top: 10px;*/
/*        padding-bottom: 10px;*/
/*        font-size: 16px;*/
/*    }*/

/*    .merchantnonnotes p {*/
/*        font-size: 20px;*/
/*        text-align: center;*/
/*        font-weight: 300;*/
/*        text-transform: uppercase;*/
/*        padding-bottom: 8px;*/
/*    }*/

/*    .subheading span {*/
/*        font-weight: 600;*/
/*        !* color: rgb(232, 56, 56); *!*/
/*        font-size: 28px;*/
/*    }*/

/*    .price {*/
/*        display: flex;*/
/*        font-size: 20px;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*    }*/
/*    .count {*/
/*        padding: 53px 47px;*/

/*    }*/
/*    .Spendinsightarraow {*/
/*        display: block;*/
/*        position: absolute;*/
/*        right: 0;*/
/*        top: 11%;*/
/*        font-size: 25px;*/
/*        left: 80%;*/

/*    }*/
/*    .erow {*/
/*        width: 87px;*/
/*        color: #000;*/
/*        font-size: 25px;*/
/*    }*/

/*    .Spendinsightarraow a {*/
/*        text-decoration: none;*/
/*        color: #004AAD;*/
/*        font-size: 25px;*/
/*    }*/

/*}*/

/*@media (max-width: 1433px) {*/

/*    .topcategory p {*/
/*        font-size: 25px;*/
/*        color: #000;*/

/*    }*/

/*    .topcategory img {*/
/*        width: 130px;*/
/*        height: 119px;*/
/*        margin-right: 70px;*/
/*    }*/

/*    .Heading p {*/
/*        font-size: 32px;*/
/*        font-weight: 600;*/
/*        margin-right: 60px;*/
/*    }*/

/*    .subhead {*/
/*        text-align: center;*/
/*        font-size: 28px;*/
/*        font-weight: 300;*/
/*        color: #004AAD;*/
/*        margin-top: 50px;*/
/*        margin-right: 3%;*/
/*    }*/
/*    .expensenonnotes {*/
/*        display: grid;*/
/*        grid-template-columns: 1fr 2fr 1fr;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 128px;*/
/*        background: rgba(217, 217, 217, 0.12);*/
/*        border: 1px solid #000000;*/
/*        border-radius: 1px;*/
/*        width: 401px;*/
/*    }*/

/*    .count p {*/
/*        background: #ffffff;*/
/*        color: #000;*/
/*        width: 44px;*/
/*        font-size: 21px;*/
/*        border: 1px solid #000;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 43px;*/
/*        border-radius: 50%;*/
/*    }*/

/*    .mainpath {*/
/*        position: absolute;*/
/*        left: 27%;*/
/*        gap: 65px;*/
/*        padding-top: 45px;*/
/*        padding-bottom: 100px;*/
/*    }*/

/*    .shopEnter {*/
/*        color: #e60c1b;*/
/*        padding-top: 10px;*/
/*        padding-bottom: 10px;*/
/*        font-size: 16px;*/
/*    }*/

/*    .merchantnonnotes p {*/
/*        font-size: 20px;*/
/*        text-align: center;*/
/*        font-weight: 300;*/
/*        text-transform: uppercase;*/
/*        padding-bottom: 8px;*/
/*    }*/

/*    .subheading span {*/
/*        font-weight: 600;*/
/*        !* color: rgb(232, 56, 56); *!*/
/*        font-size: 28px;*/
/*    }*/

/*    .price {*/
/*        display: flex;*/
/*        font-size: 20px;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*    }*/
/*    .count {*/
/*        padding: 53px 47px;*/

/*    }*/
/*    .Spendinsightarraow {*/
/*        display: block;*/
/*        position: absolute;*/
/*        right: 0;*/
/*        top: 11%;*/
/*        font-size: 25px;*/
/*        left: 80%;*/

/*    }*/
/*    .erow {*/
/*        width: 87px;*/
/*        color: #000;*/
/*        font-size: 25px;*/
/*    }*/

/*    .Spendinsightarraow a {*/
/*        text-decoration: none;*/
/*        color: #004AAD;*/
/*        font-size: 25px;*/
/*    }*/

/*}*/

/*@media (max-width: 450px) {*/

/*    .topcategory p {*/
/*        font-size: 13px;*/
/*        color: #000;*/

/*    }*/

/*    .topcategory img {*/
/*        width: 70px;*/
/*        height: 67px;*/
/*        margin-right: 70px;*/
/*    }*/

/*    .Heading p {*/
/*        font-size: 24px;*/
/*        font-weight: 600;*/
/*        margin-right: 60px;*/
/*    }*/

/*    .subhead {*/
/*        text-align: center;*/
/*        font-size: 25px;*/
/*        font-weight: 300;*/
/*        color: #004AAD;*/
/*        margin-top: 50px;*/
/*        margin-right: 60px;*/
/*    }*/
/*    .expensenonnotes {*/
/*        display: grid;*/
/*        grid-template-columns: 1fr 2fr 1fr;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 128px;*/
/*        background: rgba(217, 217, 217, 0.12);*/
/*        border: 1px solid #000000;*/
/*        border-radius: 1px;*/
/*        width: 300px;*/
/*    }*/

/*    .count p {*/
/*        background: #ffffff;*/
/*        color: #000;*/
/*        width: 24px;*/
/*        font-size: 13px;*/
/*        border: 1px solid #000;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        height: 26px;*/
/*        border-radius: 50%;*/
/*    }*/

/*    .mainpath {*/
/*        position: absolute;*/
/*        left: 0%;*/
/*        gap: 65px;*/
/*        padding-top: 45px;*/
/*        padding-bottom: 100px;*/
/*    }*/

/*    .shopEnter {*/
/*        color: #e60c1b;*/
/*        padding-top: 10px;*/
/*        padding-bottom: 10px;*/
/*        font-size: 12px;*/
/*    }*/

/*    .merchantnonnotes p {*/
/*        font-size: 14px;*/
/*        text-align: center;*/
/*        font-weight: 300;*/
/*        text-transform: uppercase;*/
/*        padding-bottom: 8px;*/
/*    }*/

/*    .subheading span {*/
/*        font-weight: 600;*/
/*        !* color: rgb(232, 56, 56); *!*/
/*        font-size: 23px;*/
/*    }*/

/*    .price {*/
/*        display: flex;*/
/*        font-size: 18px;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*    }*/
/*    .count {*/
/*        padding: 5vh 6vw;*/

/*    }*/
/*    .Spendinsightarraow {*/
/*        display: block;*/
/*        position: absolute;*/
/*        right: 0;*/
/*        top: 11%;*/
/*        font-size: 25px;*/
/*        left: 80%;*/

/*    }*/
/*    .erow {*/
/*        width: 87px;*/
/*        color: #000;*/
/*        font-size: 20px;*/
/*    }*/

/*    .Spendinsightarraow a {*/
/*        text-decoration: none;*/
/*        color: #004AAD;*/
/*        font-size: 15px;*/
/*    }*/

/*}*/

/* setbudget.css */

/* Styles for the popup container */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 700px;
    max-width: 90%;
    font-family: Arial, sans-serif;
    max-height: 620px;
}

.popup h2 {
    font-size: 24px;
    margin: 0 0 20px;
    text-align: center;
    color: black;
    padding-bottom: 16px;
}

/* Styles for the scroll-container */
.scroll-container {
    max-height: 400px;
    overflow: auto;
    padding-right: 20px;
}

/* Styles for the input fields */
.input-container-cat {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.input-container-cat label {
    display: block;
    margin-bottom: 5px;
    font-size: 23px;
    color: #333;
    padding-left: 15px;
    /* font-weight: bold; */

}

.input-container-cat input {
    /* Use flex: 1 to make all input fields take up equal widths */
    /* flex: 1; */
    /* OR use a fixed percentage to set the width */
    /* width: 70%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px; */
    flex: 1;
    /* OR use a fixed percentage to set the width */
    /* width: 70%; */
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Styles for the categories */
.category-container {
    margin-top: 20px;
}

.category-container p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: blue;
    font-weight: bold;
    padding-left: 25px;
    padding-bottom: 10px;
}

.category-container .input-container-cat {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    /* padding-right: 0px; */

}

.category-container .input-container-cat label {
    flex: 1;
    display: flex;
    align-items: center;

}

/* Optional: If you have category images */
.category-container .input-container-cat label img {
    margin-right: 10px;
    max-width: 50px;
    max-height: 50px;
}

/* Styles for the category input fields */
.category-container .input-container-cat input {
    /* Use flex: 1 to make all input fields take up equal widths */
    flex: 1;
    /* OR use a fixed percentage to set the width */
    /* width: 70%; */
    padding: 12px;
    font-size: 23px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

/* Styles for the buttons */
.buttonclose {
    padding: 10px 15px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #008000;
    color: white;
    margin-right: 40px;
    margin-left: 220px;
    margin-top: 30px;

}

.buttonclose:hover {
    background-color: #008000;
}

.buttonsave {
    padding: 10px 15px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #004aad;
    color: white;

}

.buttonsave:hover {
    background-color: #004aad;
}

/* button:hover {
  background-color: #004aad;
} */

/* button:last-child {
  background-color: #004aad;
  margin-left: 10px;
} */

/* button:last-child:hover {
  background-color: #004aad;
} */

/* Optional: Style for the labels */
.label {
    margin-right: 10px;
}

.popup p {
    font-size: 23px;
    color: black;
    text-align: left;
    margin-top: 20px;
}

.input-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.input-container label {
    display: block;
    margin-bottom: 5px;
    font-size: 23px;
    color: #333;
    padding-left: 25px;
}

.input-container input {
    /* Use flex: 1 to make all input fields take up equal widths */
    /* flex: 1; */
    /* OR use a fixed percentage to set the width */
    /* width: 70%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px; */
    flex: 1;
    /* OR use a fixed percentage to set the width */
    /* width: 70%; */
    padding: 12px;
    margin-left: 140px;
    /* margin-right: 10px; */
    font-size: 23px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.textcat {
    display: flex;
    gap: 20px;
}

.bottomText {
    font-size: 16px;
    color: #333;
    margin-top: 25px;
    /* font-weight: 300; */
    /*margin-right: 380px;*/

}

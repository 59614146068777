/* Comments.css */

/* Styles for the container of comments */
.comments-container {
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    margin-top: 8vh;
    width: 80%;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    /*min-width: 450px;*/
}

.comments-container h1 {
    font-size: 47px;
    text-align: center;
    color: grey;
    margin-bottom: 10%;
    font-weight: 450;

}

.comments-container h2 {
    font-size: 35px;
    margin-bottom: 2%;
    margin-top: 5%;
    color: #424242;
    text-align: left;
    font-weight: 450;

}

.comments-container h3 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 1%;
    margin-top: 3%;
    color: #575757;
    font-weight: 350;

}

.comments-container p {
    text-align: left;
    font-size: 17px;
    margin-top: 1%;
    margin-bottom: 1%;
}

/* Styles for the comment header */
.comment-header {
    font-size: 1.25rem;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

/* Styles for individual comments */
.comment {
    border-bottom: 1px solid #eee;
    margin-bottom: 16px;
    padding-bottom: 16px;
}

/* Styles for the commenter's name */
.comment-name {
    font-weight: 450;
}

/* Styles for the comment date */
.comment-date {
    color: #777;
    font-size: 0.875rem;
    margin-top: 4px;
}

/* Styles for the comment text */
.comment-text {
    color: #333;
    white-space: pre-line;
    margin-top: 8px;
}

.signin-webpage {
    width: 100vw;
}

.signin-body {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
}

.left-body {
    background-color: #EAF1F6;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
}

.capitalflo-mob-img {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    margin-top: 5%;
}

.capitalflo-mob-img img {
    width: 80%;
    height: fit-content;
    object-fit: contain;
    position: relative;
}

.playstationStartup {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    align-content: center;
}

.playstationStartup img {
    width: 210px;
    height: 100%;
    align-self: center;
    vertical-align: center;
}


.capitalflo-banner-img {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
}

.capitalflo-banner-img img {
    width: 50%;
    /* height: fit-content; */
    object-fit: contain;
}

.capitalflo-tagline {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    /* margin-top: 10px; */
    height: 35px;
}

.capitalflo-tagline p {
    font-size: 20px;
    font-weight: 450;
    color: #004AAD;
    letter-spacing: 0.5px;
}


.right-body {
    background-color: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-content: center;
    align-self: center;
}


.top-right-body {
    display: flex;
    /* flex:1; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-bottom: 5%;
    border-bottom: #cac8c8;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    margin-top: 18%;
}

.playstationSignup a {
    width: 210px;
    align-self: center;
    vertical-align: center;
}

.googlesign {
    display: flex;
    align-items: center;
}

.login-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* width: 30%; */
    align-items: center;
    align-self: center;
    margin-top: 30px;
    height: 45px;
}

.dots-option {
    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor: pointer;
}

.or-text {
    position: relative;
    right: -48%;
    top: -13px;
    width: 100%;
    color: #424242;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    font-size: 18px;
}

.login-button {
    padding: 10px 20px;
    background-color: #004AAD;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 25px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    /* margin-bottom: 5%; */
    width: 25%;
    min-width: 200px;
}

.bottom-right-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 5%;
}

.demo-text {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    /* font-weight: 500; */
    color: #424242;
    cursor: pointer;
}

.inputfield {
    max-width: 300px;
    text-align: center;
}

.inputfield input {
    width: 300px;
    min-width: 300px;
    border-radius: 5px;
    /* text-align: center; */
}

.terms-startup-container {
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    justify-content: center;
    /* align-self: flex-end; */
    align-items: center;
    /* margin-top: 15%; */
    margin-bottom: 20px;
    white-space: pre-wrap;
    /* Ensures that whitespace is preserved */
    word-wrap: break-word;
    padding-bottom: 30px;
}

.terms-startup-container a {
    text-decoration: underline;
    color: #424242;
}

.terms-startup-container p {
    color: #424242;
    font-size: 15px;
}

.playstationStartup-mob {
    display: none !important;
}

.otp-text {
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
}

.otp-container {
    color: #004AAD;
    margin-top: 10px;
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
}

.animate-spin {
    margin-top: 10px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.otp-verify-button {
    width: 100%;
    max-width: 150px;
    align-self: center;
    vertical-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #004AAD;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
}


@media screen and (max-width: 1700px) {
    .capitalflo-mob-img img {
        width: 90%;
    }
}

@media screen and (max-width: 920px) {
    .capitalflo-mob-img img {
        width: 100%;
    }

    .top-right-body {
        margin-top: 15vh;
    }

    .capitalflo-mob-img {
        justify-content: center;
    }

    .playstationStartup img {
        width: 150px;
        height: 100%;
        align-self: center;
        vertical-align: center;
    }

    .or-text {
        position: relative;
        right: -46%;
    }

}

@media screen and (max-width: 600px) {
    .playstationStartup-mob {
        display: flex !important;
        flex: 1;
    }

    .left-body {
        display: none !important;
    }

    .top-right-body {
        width: 100vw;
    }

    /* .capitalflo-banner-img{
        display: none !important;
    } */

    /* .demo-text {
        display: none !important;

    } */
    

    .playstationStartup-mob {
        display: flex !important;
        /* align-items: center; */
        align-items: flex-start;
        justify-content: center;
        vertical-align: center;
        align-content: center;
    }

    .playstationStartup-mob img {
        display: flex !important;
        width: 150px;
        height: 100%;
        align-self: center;
        vertical-align: center;
        overflow: hidden;
        object-fit: contain;
    }

    .inputfield input {
        width: auto;
    }
}

@media screen and (max-width: 465px) {
    .bottom-right-body {
        width: 100%;
    }

    .inputfield input {
        width: auto;
    }

    .or-text {
        position: relative;
        right: -45%;
    }
}

@media screen and (max-width: 1500px) and (max-height: 800px) {

    /* CSS rules for screens with maximum width of 1433px and maximum height of 670px */
    .capitalflo-mob-img {
        width: 75%;
    }

    .terms-startup-container {
        padding-bottom: 0px;
        justify-content: flex-end !important;
    }
}
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

/* .postloghead{
  margin-top: 10px;
  margin: 0 auto;
  height: 100px;
  width: 1200px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.logolog{
  width: 250px;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.logolog img{
   height: 90px;
   width: 250px;
}

.servicehome{
  padding: 104px 100px 100px 100px;

}
.servicehome li {
  list-style: none;
  cursor: pointer;
}
.servicehome li a{
  text-decoration: none;
  font-size: 25px;
  color: rgba(2, 2, 2, 0.679);
}
.profilelog {
  position: absolute;
  top: 43px;
  left: 1254px;
}
.userprofileIcolog {
  border: 2px solid #1C98ED;
  border-radius: 50%;

}
.profilelog li a{
  text-decoration: none;
  color: #010000;
}

.profilelog li {
  padding-left: 5px;
}
.accountLinks{
  border: 1px solid #1c98ed;
  border-radius: 8px;
  width: 311px;
  box-shadow: #dddddddd;
  height: 268px;
  margin-top: 102px;
  background-color: #ffffff;
  margin-bottom: 100px;
}
.accountLinks ul li a{
  list-style: none;
  text-decoration: none;
}
.icun{
  width: 10px;
  height: 10px;
}
.accountLinks ul li a p{
  height: 20px;
  margin-top: 19px;
  color:#1C98ED;
}
.specialBank{
  padding-left: 40px;
  color: #1C98ED;
}

.Banks{
  padding-left: 32px;
} */
.accountLinks ul {
    overflow: hidden;
}

.servicehome li a {
    text-decoration: none;
    font-size: 25px;
    color: rgba(2, 2, 2, 0.679);
}

.bottomSpace {
    height: 350px;
}
.email-profile-container{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */

}
.demo-account-button{
    background-color: #ad2b00bd;
    /* background-color: #ad0000ec; */
    color: white;
    border-radius: 10px;
    padding: 2px 10px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}

.accountLinks {
    border-radius: 8px;
    width: 70vw;
    box-shadow: #dddddddd;
    /*height: 450px;*/
    margin-top: 150px;
    background-color: #ffffff;
    overflow: hidden;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* margin-bottom: 100px; */
}

/* .accountLinks ul li a {
  display: flex;
  align-items: center;
}
.accountLinks ul li a p{
  height: 20px;
  margin-top: 19px;
}
.specialBank{
  padding-left: 40px;
} */

/* .servicehome li a{
  text-decoration: none;
  font-size: 25px;
  color: rgba(2, 2, 2, 0.679);
} */
.userprofileIcolog {
    border: 2px solid #004AAD;
    border-radius: 50%;

}

.profilelog li a {
    text-decoration: none;
    color: #010000;
}

.profilelog li {
    padding-left: 5px;
    padding-bottom: 20px;
}

.accountLinks ul li a {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 90px;
    /* justify-content: center; */
}

.accountLinks ul li p {
    height: auto;
    margin-top: -2px;
    font-size: 23px;
    padding-left: 20px;
    color: #0e0e0f;
}

.LiStyles {
    display:flex;
    height: 10vh;
    width:30vw;
    justify-content: space-between;
}
@media (min-width: 425px) and (max-width: 768px){
    .accountLinks{
        width:80vw;
    }
    .LiStyles{
        display:flex;
        height: 15vh;
        width:70vw;
        justify-content: space-between;
    }

}
@media (max-width: 425px){
    .accountLinks{
        width:80vw;
    }
    .LiStyles{
        display:flex;
        height: 15vh;
        width:80vw;
        justify-content: space-between;
    }

}

.specialBank {
    padding-left: 5vw;
    font-size: 20px;
    /* font-weight: bold; */
}

.accountLinks ul li a {
    list-style: none;
    /* overflow: hidden; */
    text-decoration: none;
}

.accountLinks ul li {
    color: #004AAD;
    padding: 7px;
    list-style: none;
    padding: 0;
}

.accountLinks span {
    color: #0e0e0f;
}

.accountLinks li {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
}

.accountLinks .icon {
    width: 30px;
    height: 50px;
    color: #010101;
    /* margin-right: 10px; */
}

.accountLinks .count {
    font-weight: bold;
    padding: 24px;
    color: #000;
    font-size: 20px;
}

.accountLinks .icon-text {
    display: inline;
    margin: 0;
}

.accountLinks .third {
    margin-left: -2px;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is on top of other content */
}

.popup-Delete {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    max-height: 150px;
    text-align: center;
}

.popup p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
}

.popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.popup-buttons button {
    background-color: #004AAD;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
}

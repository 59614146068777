.featured-all-webpage {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredContainer {
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    margin-bottom: 10%;
}

.grid-container-all {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5vw;
    width: 80vw;
    height: auto;
}

.grid-all-item-header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.grid-all-item-info {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 3px;
    margin-top: 10px;
}

.grid-all-item-info p {
    margin-right: 10px; /* Adjust the margin as needed */
}

.grid-all-item {
    border: 0.5px solid rgb(128, 128, 128);
    border-radius: 25px;
    padding: 30px;
    transition: transform 0.3s ease;
    text-decoration: none;
    color: #424242;
}

.grid-all-item:hover {
    transform: scale(0.98);
    /*border: none;*/
    background-color: rgba(141, 141, 141, 0.07);
}

.grid-all-item img {
    width: 100%;
    height: auto;
}

.grid-all-item p {
    margin: 5px 0;
}

.grid-all-item h3 {
    margin: 10px 0;
    font-size: 22px;
}

.featured-all-title {
    & h3 {
        margin-top: 200px;
        margin-bottom: 5vh;
        font-size: 35px;
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        color: rgba(66, 66, 66, 0.85);
    }
}

@media (max-width: 600px) {
    .grid-container-all {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5vw;
        width: 80vw;
        height: auto;
    }
}

@media (max-width: 450px) {
    .grid-container-all {

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5vw;
        width: 80vw;
        height: auto;
    }
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.warned {
    width: 50px;
    height: 50px;
    color: rgba(255, 0, 0, 0.625);
    /* padding-top: 5px; */
}

.piee {
    overflow: hidden;
    background-color: #004AAD;
}

.bottomSpace {
    height: 350px;
}

/* .calender{
    color: #004AAD;
    width: 100px;
} */

.spenttotal {
    font-weight: 600;
    color: #004AAD;
}

.avgdaily {
    font-weight: 600;
    color: #004AAD;
    font-size: 20px;
}

.mainheader {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-top: 155px;
    /*width: 1200px;*/
}

.mainheader h2 {
    font-weight: 350;
    font-size: 40px;
    padding-bottom: 50px;

}
/* .calenderimg{
    margin-top: 100px;
} */
.toplayer {
    display: flex;
    align-items: center;
    /*justify-content: space-evenly;*/
    /*margin-left: 187px;*/
    /*gap: 358px;*/
    /*justify-content: center;*/
}

.toplayer img {
    width: 105px;
    height: 105px;
}

.innerlayer p {
    font-size: 20px;
    padding: 10px 0px 0px 5px;
}

.totalspend {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    margin-top: 100px;
    margin-left: 50px;
    width: 300px;
    gap: 10px;
}

.totalspend p {
    color: #004AAD;
    padding-top: 40px;
    font-size: 32px;
    font-weight: 600;
}

.insights {
    display: grid;
    position: absolute;
    left: 25%;
    padding: 28px;
    font-size: 24px;
    width: 745px;
    height: fit-content;
    margin-top: 0px;
    margin-bottom: 100px;
}

.spendIcon {
    width: 117px;
    height: 134px;
    font-weight: 100;
}

.spendcategory img {
    width: 69px;
    height: 92px;
    padding-top: 24px;
}

.spending-category {
    display: grid;
    padding-top: 35px;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    text-align: center;
}

.recuring {
    padding-top: 50px;
    margin-left: 900px;
}

.recur {
    padding-top: 13px;
    display: flex;
    gap: 85px;
    align-items: center;
    /* justify-content: center; */
}

.recur p {
    margin-left: 500px;
}

.recur span {
    position: absolute;
    right: 150px;
    padding-left: 100px;
    margin-top: 33px;
}

.minimalHeader p {
    display: flex;
    align-items: center;
    padding-top: 35px;
    justify-content: center;
    color: #004AAD;
    font-size: 32px;
    font-weight: 600;
}

.recurringDrop {
    width: max-content;
    cursor: pointer;
    display: flex;
    gap: 1vw;
    left: 70vw;
    top: 60vh;
    position: absolute;
    right: 2vw;
    /*right: 185px;*/
    /*top: 647px;*/
}

.recurringDrop h5 {
    font-size: 20px;
}

.dropdownIcon {
    width: 20px;
    height: 25px;
}

/* .listingRecur{
position: absolute;
right: 160px;
padding-left: 100px;
margin-top: 0px;
} */
.listingRecur {
    list-style: none;
    padding-top: 5px;
}

.listingRecur li {
    color: #004AAD;
}

.appearingDiv {
    padding-top: 0px;
    margin-top: 0px;
}

.inputSalary {
    width: 130px;
    height: 35px;
    color: #004AAD;
    font-weight: 600;
    font-size: 19px;
}

.IncomeInput {
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 164px;
    top: 576px;
}

.IncomeInput p {
    font-size: 20px;
    color: #004AAD;

}

.IncomeInput .buttons {
    background-color: #0aac32;
    color: #fff;
    padding: 5px;
    height: 25px;
    cursor: pointer;
}

.isInfoVisibleBankStatements {
    padding: 10px;
    position: absolute;
    top: 15vh;
    left: 62vw;
    height: auto;
    border-radius: 8px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
    max-width: 350px;
    min-width: 100px;
    contain: content;
}

.isInfoVisibleBankStatements.visible {
    display: block;
}

.isInfoVisibleBankStatements.visible {
    flex-direction: column;
    font-size: 18px;
    color: #000;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    padding: 13px;
    font-weight: 300;
}

.isInfoVisibleBankStatements {
    font-size: 16px;
    color: #000;
}

.isInfoVisibleBankInsights {
    padding: 10px;
    position: absolute;
    top: 15vh;
    left: 70vw;
    height: auto;
    border-radius: 8px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
    max-width: 300px;
    min-width: 330px;
}

.isInfoVisibleBankInsights.visible {
    display: block;
}

.isInfoVisibleBankInsights.visible {
    flex-direction: column;
    font-size: 18px;
    color: #000;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 13px;
    font-weight: 300;
}

.isInfoVisibleBankInsights {
    font-size: 16px;
    color: #000;
}

.isInfoVisibleAvgDaily {
    padding: 10px;
    position: absolute;
    top: 30vh;
    left: 16vw;
    height: auto;
    border-radius: 8px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
    max-width: 40vw;
    min-width: 330px;
}

.isInfoVisibleAvgDaily.visible {
    display: block;
}

.isInfoVisibleAvgDaily.visible {
    flex-direction: column;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.isInfoVisibleAvgDaily {
    font-size: 16px;
    color: #000;
}


.merchant-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.merchant-modal-body {
    box-sizing: border-box;
    background: #ECECEC;
    padding: 20px;
    border-radius: 20px;
    width: 50vw;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.merchant-list {
    max-height: 70vh;
}

.merchant-modal-body h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.merchant-modal-body ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
}

.merchant-modal-body button {
    background-color: #0042AD;
    color: white;
    padding: 10px 20px;
    margin-top: 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 18px;
}

.merchant-modal-body button:hover {
    background-color: #0265d0;
}

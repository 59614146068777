.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.back {
    width: 50px;
    height: auto;
}

.popupmodel {
    height: 672px;
    overflow-y: scroll;
}

.popupmodel p {
    font-size: 27px;
    position: absolute;
    font-weight: 600;
    height: 84px;
    padding-top: 36px;
    width: 100%;
    top: 0px;
    background: #fff;
    left: 42px;

}

.category {
    padding-top: 90px;
    padding-left: 46px;
    font-weight: 600;
    font-size: 20px;
    color: #004AAD;
    z-index: 1;
}

.addcategory {
    width: 90px;
    margin: 12px 52px;
    border-radius: 9px;
    height: 75px;
    background-color: #fcfcfc1f;
    display: flex;
    border: 1px solid #004AAD;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addcategory img {
    width: 49px;
    height: 56px;
}

.addiconcategory {
    width: 44px;
    height: 95px;
}

.categoryimages h2 {
    padding-top: 5px;
    padding-left: 46px;
    font-weight: 600;
    font-size: 20px;
    color: #004AAD;
}

.imagelistout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 36px;
    padding-right: 34px;
    margin-top: 11px;
    margin-bottom: 60px;
}

.eachimage {
    width: 100px;
    height: 102px;
    border: 1px solid #004AAD;
    border-radius: 5px;
    margin: 5px;
}

.insideicon {
    width: 101px;
    height: 64px;
    color: #004AAD;
}

.eachimage h3 {
    font-size: 12px;
    display: flex;
    width: 84px;
    padding-left: 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.eachimage img {
    width: 79px;
    padding-left: 19px;
    padding-top: 10px;
    height: 66px;
}

.categoryForm {
    width: 400px;
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 50px;
}

.namelabel {
    color: #004AAD;
    font-weight: 600;
}

form input {
    color: #004AAD;
}

.labeled {
    padding-top: 10px;
}

.categorybtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 365px;
    padding-top: 20px;
}

.categorybtn button {
    padding: 5px 155px 5px 155px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    border-radius: 5px;
}

.admin-login-container {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5%;
}

.admin-login-container h2 {
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit:hover {
    background-color: #0056b3;
}

.admin-blogs {
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2% auto;

    .blog-tiles-container {
        margin-top: 2%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    .blog-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #f4f4f4;
        padding: 20px;
        border-radius: 5px;
    }

    .blog-image {
        width: 100%;
        height: auto;
        max-height: 200px;
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .blog-tiles-container .blog-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #f4f4f4;
        padding: 20px;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        transition: 0.3s;
    }

    .blog-tiles-container .blog-tile:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    .blog-tiles-container .blog-tile::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        opacity: 0;
        transition: 0.3s;
    }

    .blog-tiles-container .blog-tile:hover::before {
        opacity: 1;
    }

    .blog-title {
        font-size: 16px;
        font-weight: bold;
        margin-top: 0;
    }
}

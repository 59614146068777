* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.otpForm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    /* margin-top: 25%; */
}

.otpsbt {
    background-color: #444;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    padding: 13px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 37px;
}

.otpForm h2 {
    font-weight: 400;
    margin-bottom: 10px;
    color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .otpsession{
    margin-top: 50%;
} */
/* .otpsession{
    display: flex;
    flex-direction: column;
} */

.form{

    /*margin-top:150px;*/
    /*margin-left:200px;*/
    /*border: 2px groove rgba(0, 0, 0, 0.2);*/
    border-radius:5px;
    height: auto;
    width:25vw;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.outerForm {
    width: 35vw;
    display: flex;
    flex-direction: column;
    background-color:#0d326d;
    align-items:center;
    margin-top:15vh;
    border-radius:20px;
}
.inputLines {
    display:flex;
    flex-direction:column;
    margin-top:10px;
    width:22vw;
}
.ageInput {
    display:flex;
    flex-direction:row;
    width:22vw;
}
.ageInput #age {
    height:5vh;
    border-radius:5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width:19.2vw;
}
.ageInput #date {
    height:5vh;
    border-radius:5px;
    width:43px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 425px) {
    .outerForm{
        width: 90vw

    }
    .form{
        width: 90vw;
    }
    .inputLines {
        width: 80vw;
    }
    .ageInput {
        width: 80vw;
    }
    .ageInput #age {
        width: 80vw;
    }
}
@media (max-width: 768px) and (min-width:426px) {
    .outerForm{
        width: 70vw

    }
    .form{
        width: 70vw;
    }
    .inputLines {
        width: 50vw;
    }
    .ageInput {
        width: 50vw;
    }
    .ageInput #age {
        width: 80vw;
    }
}

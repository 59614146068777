* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.bottomSpace {
    padding-bottom: 350px;
}

.scrollDivGoal {
    position: absolute;
    top: 200px;
    right: 65px;
}

.scroll-to-goals-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: .6;
}
.balanceContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.warningTextGoals {
    color:'red';
    font-size:'15px';
    height:'100%';
    text-align:'center';
}

.Text-Goals {
    /* position: absolute; */
    right: 155px;
    /* padding-left: 1200px; */
    color: #004aad;
    cursor: pointer;
}

.topie {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 150px;
    margin-bottom: 60px;
    margin-left: 50px;
}

.topie img {
    width: 60px;
    height: 60px;
}

.topie p {
    line-height: 40px;
    font-size: 40px;
    overflow: hidden;
}

.topie-AssetPlanner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 60px;
    margin-left: 50px;
    gap: 10px;
}
.topie-AssetPlanner p {
    line-height: 40px;
    font-size: 40px;
    overflow: hidden;
}
.topie-AssetPlanner img {
    align-self:flex-start;
    width: 60px;
    height: 100px;
}

.TFPsubheading {
    margin-left: 35px;
    margin-bottom: 35px;
    font-size: 25px;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* margin-top: 70px; */
    padding-bottom: 100px;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 25px;
    width: 310px;
    font-size: 20px;
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */
.alltable {
    margin-top: 30px;
    margin-bottom: 100px;
}
.alltable-assets {
    margin-top: 30px;
    margin-bottom: 100px;
}
.table-container-Assets {
    display: inline-flex;
    width: 100%;
    margin: 0px;
    padding-left: 20px;
}

.topietwo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 150px;
    margin-bottom: 10px;
}

.topietwo img {
    width: 70px;
    height: 70px;
}

.topietwo p {
    line-height: 64px;
    font-size: 57px;
    overflow: hidden;
}

.goaltable {
    margin-top: 50px;
    margin-bottom: 50px;
}

.goalTerm {
    float: right;
}

.inform {
    display: flex;
    gap: 200px;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

table th img {
    width: 60px;
    height: 30px;
    padding-left: 30px;
}

/* .savedButtonBelow {
  padding-top: 50px;
  padding-right: 900px;
} */

/* .savedButtonBelow button {
  border: 1px solid white;
  border-color: darkgray;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */

.delete-cross-tfp {
    cursor: pointer;
    font-size: 0.9rem;
    position: absolute;
    top: 5px;
    left: 5px;
    color: black;
}

/* .savedButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-right: 220px;
  padding-top: 250px;
} */

/* .savedButton button {
  border: 1px solid white;
  border-color: darkgray;
  color: white;
  background-color: #004aad;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */
/* .savedButton{
  border: 1px solid white;
  border-color: darkgray;
  width: 100px;
  height: 30px;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */
/* .savedGoalsButton {
  position: absolute;
  top: 1800px;

  right: 10px;
  padding-right: 220px;
  padding-bottom: 500px;
} */

/* .savedGoalsButton button {
  border: 1px solid white;
  border-color: darkgray;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */

.sumTotal {
    font-weight: bold;
}

.savebackground {
    display: block;
    width: fit-content;
    height: 100px;
    padding: 10px;
    z-index: 100;
    left:80vw;
    /* display: flex;
    align-items: center;
    justify-content: flex-end; */
    position: fixed;
    background-color: #fff;
}
.goalsSaveButton {
    display: block;
    width: fit-content;
    height: 100px;
    padding: 10px;
    z-index: 100;
    /* display: flex;
    align-items: center;
    justify-content: flex-end; */
    position: fixed;
    top:20vh;
    right:5vw;
    background-color: #fff;
}

.savedButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #004aad;
    padding: 12px;
    color: #fff;
    margin-right: 20%;
    width: 150px;
    cursor: pointer;
}

.sticky-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 100px;
}

.info-popupEmergency {
    padding: 10px;
    position: absolute;
    top: 420px;
    left: 250px;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupEmergency.visible {
    display: block;
}

.info-popupEmergency.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupGoals {
    font-size: 16px;
    color: #000;
}

.info-popupGoals {
    padding: 10px;
    position: absolute;
    top: 20vh;
    left: 60vw;
    height: auto;
    max-width: 300px;
    border-radius: 5px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
}

.info-popupGoals.visible {
    display: block;
}

.info-popupGoals.visible p {
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 300;
}
.info-popupLiquid {
    font-size: 16px;
    color: #000;
}

.info-popupLiquid {
    padding: 10px;
    position: absolute;
    top: 40vh;
    left: 10vw;
    height: auto;
    max-width: 300px;
    border-radius: 5px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
}

.info-popupLiquid.visible {
    display: block;
}

.info-popupLiquid.visible p {
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 300;
}

.info-popupEmergency {
    font-size: 16px;
    color: #000;
}


.info-popupSalary {
    /* margin-left: 120px; */
    /* margin-bottom: 12px; */
    padding: 10px;
    position: absolute;
    top: 70%;
    left: 240px;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupSalary.visible {
    display: block;
}

.info-popupSalary.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupSalary {
    font-size: 16px;
    color: #000;
}


.info-popupLoans {
    padding: 10px 10px 10px 10px;
    position: absolute;
    top: 200vh;
    left: 65vw;
    height: auto;
    width: auto;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

/* Media query for even smaller screens */

.info-popupLoans.visible {
    display: block;
}

.info-popupLoans.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupLoans {
    font-size: 16px;
    color: #000;
}

.info-popupInvestments {
    padding: 10px;
    position: absolute;
    top: 230vh;
    left: 256px;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupInvestments.visible {
    display: block;
}

.info-popupInvestments.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupInvestments {
    font-size: 16px;
    color: #000;
}

.info-popupVariable {
    padding: 10px;
    position: absolute;
    top: 200vh;
    left: 69vw;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupVariable.visible {
    display: block;
}

.info-popupVariable.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupVariable {
    font-size: 16px;
    color: #000;
}

.info-popupTFP {
    padding: 10px;
    position: absolute;
    top: 25vh;
    left: 68vw;
    height: auto;
    max-width: 300px;
    min-width: 100px;
    contain: content;
    border-radius: 5px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
}

.info-popupTFP.visible {
    display: block;
}

.info-popupTFP.visible p {
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 300;
}

.info-popupTFP {
    font-size: 16px;
    color: #000;
}

.info-popupCashFlow {
    padding: 10px;
    position: absolute;
    top: 160vh;
    left: 33vw;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupCashFlow.visible {
    display: block;
}

.info-popupCashFlow.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupCashFlow {
    font-size: 16px;
}

.info-popupNetWorth {
    padding: 10px;
    position: absolute;
    top: 280vh;
    left: 18vw;
    height: 40px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}

.info-popupNetWorth.visible {
    display: block;
}

.info-popupNetWorth.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.info-popupNetWorth {
    font-size: 16px;
}

@media only screen and (max-width: 480px) {
    /* td, th {
        padding:5px;
        width: 10vw;
    } */
    .info-popupLoans {
        top: 145vh; /* Adjust the top value for even smaller screens */
        left: 50vw;
    }

    .info-popupInvestments {
        top: 1900px;
        left: 15vw;
    }
}

/* Media query for smaller screens */
@media only screen and (min-width: 480px) and (max-width: 1023px) {
    .info-popupLoans {
        top: 145vh; /* Adjust the top value for smaller screens */
        left: 50vw;
    }

    .info-popupNetWorth {
        top: 230vh;
        left: 15vw
    }

    .info-popupInvestments {
        top: 1900px;
        left: 15vw;
    }
}


@media only screen and (min-width: 1025px) {
    .info-popupLoans {
        top: 1800px; /* Adjust the top value for smaller screens */
        left: 60vw;
    }

    .info-popupNetWorth {
        top: 2480px;
        left: 250px;
    }

    .info-popupInvestments {
        top: 2300px;
        left: 250px;
    }

    .info-popupVariable {
        top: 1130px
    }
}

.accountSettings {
    margin-top: 100px;
}

.accountSettings li .link {
    display: flex;
    flex-direction: row;
    padding: 29px;
    text-decoration: none;
    color: inherit;
    align-items: flex-start;
}

.accountSettings li .link .icon {
    width:25px;
    height:25px;
    margin-top:2px;
    margin-right:15px;

}
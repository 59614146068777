/* Styles for the locked and unlocked content */
.main-body-div {
    margin-top: 20vh;
    display: flex;
    min-width: 300px;
    width: 30vw;
    max-width: 600px;
}

.locked-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}

.locked-content p {
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 30px;
}

.locked-content h2 {
    font-size: 50px;
}

.locked-content svg {
    width: 50px;
    height: 50px;
}

.locked-content-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 15px;
}

.unlocked-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border: 1px solid #4caf50;
    border-radius: 5px;
    background-color: #e8f5e9;
    margin-bottom: 20px;
}

/* Styles for the payment button */
.payment-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-button:hover {
    background-color: #45a049;
}
.isInfoVisibleFA {
    padding: 10px;
    position: absolute;
    top: 15vh;
    left: 68vw;
    height: auto;
    border-radius: 8px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
    max-width: 40vw;
    min-width: 330px;
}

.isInfoVisibleFA.visible {
    display: block;
}

.isInfoVisibleFA.visible {
    flex-direction: column;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: 300;
}

.isInfoVisibleFA {
    font-size: 16px;
    color: #000;
}

/* ResponsiveModal.css */
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}


.modal-overlay {
    display: none;
}

@media (max-width: 450px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Responsivemodal {
        position: absolute;
        top: 80px;
        left: 26px;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        width: 84%;
        height: 269px;
        max-width: 400px;
    }

    .modal-close-button {
        width: 238px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        /* margin-top: 100px; */
        color: #fff;
        padding:8px;
        border: none;
        background-color: #004AAD;
        /* background: none; */
        /* border: none; */
        /* position: absolute; */
        /* top: 10px; */
        /* right: 10px; */

        cursor: pointer;
    }

    .modal-content {
        margin-top: 20px;
        margin-bottom: 39px;
    }

    .modal-content p {
        color: #757575;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
    }

    .haedingRes {
        padding-bottom: 35px;
        font-weight: 600;
        font-size: 18px;
        /* letter-spacing: 0px; */
    }
}

/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */

* {
    margin: 0px;
}


code {
    font-family: 'Lato', sans-serif;
}

@media (max-width: 1920px) {
    body {
        margin: 0;
        font-family: 'Lato', sans-serif;
    }
}

@media (max-width: 1500px) {
    body {
        margin: 0;
        font-family: 'Lato', sans-serif;
        /* width: 100px; */
    }
}

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 900px) {

}

@media (max-width: 600px) {

}

@media (max-width: 450px) {

}

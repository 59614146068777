.customGPlusSignIn {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
   padding-top: 2px;
   padding-bottom: 2px;
   margin-left: 40px;
   margin-right: 40px;
    /* font-size: 20px; */
}
.google-wrapper {
    border: 1px solid #777777;
    border-radius: 20px;
}
.google-wrapper:hover {
    background-color: #fcfcfc;
    border: 1px solid #cccccc;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.google-icon img {
    width: 25px;
    height: 35px;
    object-fit: contain;
    cursor: pointer;
}

.google-buttonText {
    display: flex;
    font-size: 18px;
    color: #424242;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding-left: 20px;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}


* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

}

.signin-webpage {
    width: 100vw;
}


.signboundry {
    height: 1100px;
}

.googlesign {
    display: flex;
    align-items: center;
}

.getapp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.getapp p {
    /*padding-top: 20px;*/
    font-size: 20px;
}

.playstationSignup {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    align-content: center;
}

.playstationSignup img {
    width: 210px;
    height: 100%;
    align-self: center;
    vertical-align: center;
}

.playstationSignup a {
    width: 210px;
    align-self: center;
    vertical-align: center;
}

/* .googlesign{
    display: none;
} */
.Invalidmessage {
    color: #f00622;
}

.loginsignboundry {
    height: 765px;
    overflow: hidden;
}

.loginboundry {
    transition: all 0.3s ease-in-out;
    display: flex;
    margin-top: 90px;
    justify-content: center;
    padding-top: 84px;
    margin: 0 auto;
    width: auto;
    height: auto;
}

.loginsubmitpanel ul {
    display: flex;
    align-items: center;
    gap: 30px;
}

.loginsubmitpanel ul li {
    list-style: none;
}

.loginforming {
    transition: all 0.3s ease-in-out;
    /*padding-top: 50px;*/
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 50px;
    width: auto;
    /*margin-top: 32px;*/
    border-radius: 20px;
    background: rgba(225, 232, 241, 0.14);
}

.logininputfield {
    /* background-color: blueviolet; */
    width: auto;
    margin: 5px;
    display: flex;
}

.eyeicon {
    width: 30px;
    position: absolute;

}

input {
    width: auto;
    height: 44px;
    padding: 10px;
    border: 1px solid rgba(49, 48, 48, 0.31);
    background-color: #FFFFFF;
    color: black;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.loginlabel {
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
}

.logininputbundle {
    padding-top: 20px;
}

.logininputbundle:first-child {
    padding-top: 100px;
}

input:focus {
    border-color: #1c98ed;
    box-shadow: 2px 2px 5px #1c98ed;
}

.loginforming p {
    font-weight: 100;
    font-size: 13px;
    padding: 20px 0px;
}

.loginsubmitpanel ul li a {
    text-decoration: none;
    /* color: ; */
}

.loginsubmitpanel {
    margin-top: 20px;
}

.loginsubmitpanel ul li:first-child {
    background-color: #004AAD;
    padding: 8px 9px;
    border-radius: 4px;
    border: #004AAD;
}

.loginsubmitpanel ul li:first-child:hover {
    background-color: #023f8f;
}

.loginsubmitpanel ul li:first-child a {
    color: #FFFFFF;
    padding: 6px 13px;
}

.loginsubmitpanel ul li:last-child {
    background: #ffffff;
    /* border: 1px solid #004AAD; */
    border: 3px solid #ddddddb8;
    border-radius: 4px;
    padding: 7px 3px;
    transition: background-color 0.5s ease;
}

.loginsubmitpanel ul li:last-child:hover {
    background-color: #e9e9e9;
}

.loginsubmitpanel ul li:last-child a {
    color: #004AAD;
    padding: 6px 13px;
}

.logingoogleinput {
    margin-bottom: 22px;
    text-align: center;
    padding-top: 9px;
    display: flex;
    width: 373px;
    border-radius: 5px;
    align-items: center;
    background: #fff;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgb(220 220 220);
}

.loginheading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    color: #004AAD;
    margin-top: 101px;
    line-height: auto;
}

.loginheading p {
    color: rgba(0, 0, 0, 0.56);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-top: 20px;
    line-height: 34px;
}

form hr {
    width: 370px;
}

form a {
    color: #004AAD;
    font-weight: 500;
    text-decoration: none;
}

@media (max-width: 600px) {
    .signboundry {
        height: 924px;
    }

    .boundry {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: auto;
        height: 770px;

    }

    .submitpanel ul {
        display: flex;
        align-items: center;
        gap: 25px;
    }

    .submitpanel ul li {
        list-style: none;
    }

    .forming {
        padding-top: 26px;
        padding-right: 54px;
        padding-bottom: 26px;
        padding-left: 54px;
        width: 450px;
        align-self: center;
        align-content: center;
        margin-top: 32px;
        border-radius: 20px;
        background: rgba(225, 232, 241, 0.14);
    }

    .inputfield {
        width: auto;
        margin: 5px;
    }

    input {
        width: 300px;
        height: 44px;
        padding: 10px;
        border: 1px solid rgba(49, 48, 48, 0.31);
        background-color: #FFFFFF;
        color: black;
        font-size: 16px;
        outline: none;
        transition: all 0.3s ease-in-out;
    }

    .googlesigntryforfreeSignup {
        width: auto;
    }

    .label {
        font-size: 16px;
        font-style: normal;
        font-weight: 100;
    }

    .inputbundle {
        padding-top: 28px;

    }

    .loginheading {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        margin-top: 10%;
        align-items: flex-start;
        align-self: flex-start;
        margin-left: 7%;
    }

    .inputbundle:first-child {
        padding-top: 100px;
        /* padding-bottom: 23px; */
    }

    input:focus {
        border-color: #1c98ed;
        box-shadow: 2px 2px 5px #1c98ed;
    }

    .forming p {
        font-weight: 100;
        font-size: 11px;
        padding: 20px 0px;
    }

    .submitpanel ul li a {
        text-decoration: none;
        /* color: ; */
    }

    .submitpanel ul li:first-child {
        background-color: #004AAD;
        padding: 7px 3px;
        border-radius: 4px;
        border: #004AAD;
    }

    .submitpanel ul li:first-child:hover {
        background-color: #023f8f;
    }

    .submitpanel ul li:first-child a {
        color: #FFFFFF;
        padding: 6px 13px;
    }

    .submitpanel ul li:last-child {
        /* background: #E1E8F1; */
        background: #ffffff;
        /* border: 1px solid #004AAD; */
        border: 3px solid #ddddddb8;
        border-radius: 4px;
        padding: 7px 5px;
        transition: background-color 0.5s ease;
    }

    .submitpanel ul li:last-child:hover {
        background-color: #e9e9e9;
    }

    .submitpanel ul li:last-child a {
        color: #004AAD;
        padding: 6px 13px;
    }

    .googleinput {
        margin-bottom: 30px;
    }

    .heading h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        text-transform: uppercase;
        color: #004AAD;
        margin-top: 109px;
    }

    .heading p {
        color: rgba(0, 0, 0, 0.56);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        padding-top: 8px;
        line-height: 34px;
    }

    form hr {
        width: 370px;
    }

    form p span {
        color: #004AAD;
        font-weight: 600;
    }

    .playstationSignup {
        display: flex;
        align-items: center;
        width: 100vw; /* Set the width of the container */
    }

    .playstationSignup img {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0;
        justify-content: center;
        flex-grow: 1; /* Take available space */
        /* Add more styling as needed */
    }

    /* Styling for .googleplayImg */
    .playstationSignup a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-grow: 1; /* Take available space */
        /* Add more styling as needed */
    }

    .logininnerLayer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 0 auto;
    }

}


@media (max-width: 450px) {

    .loginsignboundry {
        height: auto;
        overflow: hidden;
    }

    .loginboundry {
        height: auto;
        max-height: 80%;
    }

    .logininnerLayer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 0 auto;
    }

    .signingoogle {
        display: none;
    }

    /* .googlesign{
        display: contents;
    } */
    .loginboundry {
        display: flex;
        margin-top: 0;
        justify-content: center;
        margin: 0 0;
        width: auto;
        height: auto;
    }

    .loginsubmitpanel ul {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .loginsubmitpanel ul li {
        list-style: none;
    }

    .loginforming {

        padding: 35px 54px;
        width: 318px;
        /*margin-top: 32px;*/
        border-radius: 20px;
        background: rgba(225, 232, 241, 0.14);
    }

    .logininputfield {
        /* background-color: blueviolet; */
        width: auto;
        margin: 5px;
    }

    input {
        width: auto;
        height: 44px;
        padding: 10px;
        border: 1px solid rgba(49, 48, 48, 0.31);
        background-color: #FFFFFF;
        color: black;
        font-size: 16px;
        outline: none;
        transition: all 0.3s ease-in-out;
    }

    .loginlabel {
        font-size: 18px;
        font-style: normal;
        font-weight: 100;
    }

    .logininputbundle {
        padding-top: 20px;
    }

    .logininputbundle:first-child {
        padding-top: 100px;
    }

    input:focus {
        border-color: #1c98ed;
        box-shadow: 2px 2px 5px #1c98ed;
    }

    .loginforming p {
        font-weight: 100;
        font-size: 13px;
        padding: 20px 0px;
    }

    .loginsubmitpanel ul li a {
        text-decoration: none;
        /* color: ; */
    }

    .loginsubmitpanel {
        margin-top: 20px;
    }

    .loginsubmitpanel ul li:first-child {
        background-color: #004AAD;
        padding: 8px 9px;
        border-radius: 4px;
        border: #004AAD;
    }

    .loginsubmitpanel ul li:first-child:hover {
        background-color: #023f8f;
    }

    .loginsubmitpanel ul li:first-child a {
        color: #FFFFFF;
        padding: 6px 13px;
    }

    .loginsubmitpanel ul li:last-child {
        background: #ffffff;
        /* border: 1px solid #004AAD; */
        border: 3px solid #ddddddb8;
        border-radius: 4px;
        padding: 7px 3px;
        transition: background-color 0.5s ease;
    }

    .loginsubmitpanel ul li:last-child:hover {
        background-color: #e9e9e9;
    }

    .loginsubmitpanel ul li:last-child a {
        color: #004AAD;
        padding: 6px 13px;
    }

    .logingoogleinput {
        margin-bottom: 22px;
        text-align: center;
        padding-top: 9px;
        display: flex;
        width: 206px;
        border-radius: 5px;
        align-items: center;
        background: #fff;
        justify-content: center;
        cursor: pointer;
        border: 1px solid rgb(220 220 220);
    }

    .loginheading {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        margin-top: 10%;
        align-items: flex-start;
        align-self: flex-start;
        margin-left: 7%;
    }

    .loginheading h2 {
        padding-top: 0;
        margin-top: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #004AAD;
        line-height: 34px;
    }

    .loginheading p {
        color: rgba(0, 0, 0, 0.56);
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 34px;
    }

    /*.playstationSignup{*/
    /*    display: flex;*/
    /*    !*flex-grow: 0.35;*!*/
    /*    flex-direction: row;*/
    /*    align-self: center;*/
    /*    align-items: center;*/
    /*    align-content: center;*/
    /*    justify-content: space-around;*/
    /*    vertical-align: center;*/
    /*    !*background-color: #0e0e0f;*!*/
    /*    !*width: 80%;*!*/
    /*}*/
    /*.playstationSignup img{*/
    /*    padding: 0;*/
    /*    margin: 0;*/
    /*    !*height: 100px;*!*/
    /*    gap: 0;*/
    /*    align-self: center;*/
    /*    width: 30%;*/
    /*    height: 100%;*/
    /*    !*align-items: center;*!*/
    /*    !*align-content: center;*!*/
    /*    !*justify-content: space-around;*!*/
    /*    !*flex-grow: 1;*!*/
    /*    !*vertical-align: center;*!*/
    /*    !*width: fit-content;*!*/
    /*    !*height: fit-content;*!*/
    /*}*/
    /*.playstationSignup a{*/
    /*    display: flex;*/
    /*    margin: 0;*/
    /*    padding: 0;*/
    /*    !*width: 33%;*!*/
    /*    !*height: 100%;*!*/
    /*    width: 60%;*/
    /*    height: 100%;*/
    /*    align-self: center;*/
    /*    align-content: center;*/
    /*    justify-content: space-around;*/
    /*    vertical-align: center;*/
    /*    align-items: center;*/
    /*}*/
    .playstationSignup {
        display: flex;
        align-items: center;
        width: 70vw; /* Set the width of the container */
    }

    .playstationSignup img {
        display: flex;
        align-items: center;
        width: 70%;
        padding: 0;
        justify-content: center;
        flex-grow: 1; /* Take available space */
        /* Add more styling as needed */
    }

    /* Styling for .googleplayImg */
    .playstationSignup a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-grow: 1; /* Take available space */
        /* Add more styling as needed */
    }


    form hr {
        width: 370px;
    }

    form a {
        color: #004AAD;
        font-weight: 500;
        text-decoration: none;
    }
}

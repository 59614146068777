.left-top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20vh;
    width: 50vw;
    /*align-content: center;*/
    /*align-self: center;*/
    /*align-items: center;*/
    margin-left: 10%;
}

.gif-our-solution-container {
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.gif-our-solution-container img {
    width: 70vw;
}

.top-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
}

.home-page-top-right {
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    /*width: 30vw;*/
    margin-top: 20vh
}

.top-right-img {
    margin-top: 200px
}

.top-right-img img {
    margin-top: 200px;
    height: 70vh;
}

.screen-img {
    /*margin-top: 200px;*/
    height: 70vh;
    width: auto;
}

.image-container-mockup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
}


.HeroText {
    text-align: left;
    width: 100%;
    margin-bottom: 10%;
}

.HeroText H1 {
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 6rem;
    padding-top: 10px;
    color: rgba(0, 0, 0, 0.86);
}


.playstationTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7%;
    object-fit: contain;
}

.playstationTop img {
    width: 15vw;
    min-width: 120px;
    object-fit: contain;
}


.googleplayImg {
    object-fit: contain;
}


.mid-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mid-hero-text {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 7%;
    margin-bottom: 4%;
    align-self: center;
}

.mid-hero-text p {
    font-style: normal;
    font-weight: 300;
    font-size: 31px;
    line-height: 56px;
    padding-top: 10px;
    color: #00000089;
}


.mid-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mid-full-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.mid-img-container img {
    width: 50vw;
}

.mid-full-img-container img {
    width: 70vw;
}

.bank-ss-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bank-ss-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
    gap: 5vw;
}

.bank-ss-container img {
    width: 20vw;
}

.bank-carousel {
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
    margin-left: 8vw;
}

.home-blog-subscribe {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgba(23, 98, 155, 0.68);
    height: 100%;
}

.home-blog-subscribe input {
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 5px;
    min-width: 300px;
}

.home-blog-subscribe button {
    margin-top: 5%;
    margin-right: 0;
    margin-bottom: 5%;
    border-radius: 5px;
    margin-left: 5px;
    color: #dedbdb;
    background: #004aad;
    padding: 10px 20px;
    border: #004aad;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: 300;
    margin: 0 15px;
    overflow-y: hidden;
    /*padding: 10px 1px;*/
    text-align: center;
    transition: background-color .5s ease;
}

.home-blog-subscribe form {
    /*max-width: 70%;*/
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 0;
}


.footerContainer {
    display: flex;
    flex-direction: column;
    background: #fafafb;
}

.footer-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-top img {
    width: 251px;
    height: 94px;
    margin-left: 2%;
}

.footer-top p {
    font-size: 20px;
    padding-top: 1%;
    padding-left: 5%;
    color: #424242;
}

.footerLogo {
    padding: 30px 30px 20px 49px;
    overflow: hidden;
}

.playstation {
    display: flex;
    margin-left: 5%;
    margin-top: 3%;
    overflow: hidden;
    gap: 20px;
}

.playstation img {
    width: 15vw;
    height: auto;
    object-fit: contain;
}

.footer-mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-mid-left {
    display: flex;
    padding-left: 5%;
    justify-content: flex-start;
    margin-top: 52px;
    text-decoration: none;
}

.footer-mid-left ul {
    display: flex;
    gap: 5vw;
    text-decoration: none;
}

.footer-mid-left a {
    color: #424242;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

.footer-mid ul li {
    list-style: none;
    font-size: 20px;
    text-decoration: none;
}

.footer-mid-right {
    padding-right: 5%;
}

.footer-mid-top-right {
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    margin-bottom: 30px;
    text-decoration: none;
    justify-content: flex-end;
}

.footer-mid-top-right ul {
    display: flex;
    gap: 5vw;
    text-decoration: none;
}

.footer-mid-top-right a {
    color: #424242;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
}

.footersocial {
    display: flex;
    justify-content: flex-end;
}

.footersocial ul {
    display: flex;
    gap: 2%;
}

.footersocial ul li {
    list-style: none;
    font-size: 60px;
}

.linkedin {
    color: #0077B5;
    width: 90%;
}

.instagram {
    color: #E4405F;
    width: 90%;
}

.twitter {
    color: #424242;
    width: 85%;
}

.youtube {
    color: #FF0000;
    width: 90%;
}

.lining {
    border-bottom: 0.5px solid #d1d0d0;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    margin-right: 5%;
    margin-top: 1%;
}

.footer-bottom-left {
    display: flex;
}

.footer-bottom-left {
    width: 100%;
    margin-bottom: 2%;
    margin-left: 1%;
}

.copyrightSM p {
    color: #424242;
    font-size: .85rem;
}

.footer-bottom-right {
    display: flex;
    justify-content: flex-end;
    gap: 5%;
    width: 100%;
}

.footer-bottom-right ul li {
    list-style: none;
}

.footer-bottom-right ul {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 5%;
}

.footer-bottom-right a {
    text-decoration: none;
    color: #424242;
}

.home-page-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mid-video-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    margin-top: 10%;
    width: 70vw;
    gap: 5%;
}

.mid-video-container p {
    font-size: 3.5rem;
    /* width: 30%; */
    font-weight: 400;
    color: #424242;
    /* font-family: 'Roboto', sans-serif; */
    text-align: right;
}

.products_video-homepage {
    display: flex;
    min-width: 40vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.products_video-homepage iframe {
    width: 40vw;
    height: calc(40vw * 9 / 16);
}

.talk-to-an-advisor {
    display: flex;
    min-height: 100vh;
    height: 100%;
    width: 100vw;

    align-items: center;
    justify-content: center;
    /* background-color: #f0f8ff; */
    background-color: "white";
    overflow: visible;
    /* padding: 20px; */
}

.book-a-call-main-listing {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 10%;
    /* background-color: #ccedf4; */
    background-color: #ffffff;
    height: 100%;
    overflow: visible;
    width: 100vw;

}

.left-section {
    flex: 0.65;
    padding: 20px;
    /* background-image: url("https://capitalflophotos.s3.ap-south-1.amazonaws.com/office.gif"); */
    display: flex;
    height: 100%;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    background-size: 50%;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.left-section h1 {
    color: #ff9a8e;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2px;
    /* line-height: 20px; */
    margin: 0 0 .5rem;
    text-transform: uppercase;
    margin-top: 30px;
}

.left-section h2 {
    font-size: 44px;
    color: #168ad7;
    margin-top: 10px;
    font-weight: 1000;
    /* line-height: 1; */
    letter-spacing: -2.0px;
}

.office {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex: 1;
    margin-top: 20px;
}

.office-image {
    width: 70%;
    object-fit: contain;
}

.right-section {
    flex: 1;
    padding: 50px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    align-self: flex-end;
    /* top: 50px; */
    color: #424242;
}

.right-section h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.right-section p {
    font-size: 18px;
    margin-bottom: 50px;
}

.right-section ul {
    list-style: none;
    margin-bottom: 20px;
}

.right-section ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    margin-bottom: 20px;
}

.right-section li strong {
    display: block;
    font-size: 18px
        /* margin-bottom: 5px; */
}

.right-section li {
    display: block;
    font-size: 18px;
    /* line-height: 1.25rem; */
    max-width: 300px;
    /* margin-bottom: 5px; */
}

.buttons {
    display: flex;
    margin-top: 50px;
}

.book-call,
.whatsapp {
    padding: 13px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-call {
    background-color: #209ef3;
    color: white;
}

.book-a-call-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .book-a-call-popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }   */
.book-a-call-popup-content {
    /* position: absolute;
    top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    align-self: center;
    flex-direction: row;
    color: #424242;
    background-color: #ffffff;
    max-width: 700px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.book-a-call-popup-content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding: 30px;
    border-right: #42424224 1px solid;
}

.book-a-call-popup-content-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding: 30px;
}

.book-a-call-popup-content-left .capitalflo {
    background-color: rgba(0, 0, 0, 0.08);
    color: rgb(26, 26, 26);
    border-radius: 25px;
    padding: 6px 10px;
    font-size: 0.8125rem;
    margin-bottom: 8px;
    font-weight: 500;
}

.capitalflo_back_arrow {
    padding: 10px;
    cursor: pointer;
    font-size: 25px;
}

.select-time-button {
    background-color: #209ef3;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: flex-end;
}

.time-30-mins {
    color: #706e6e;
    border: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.radio-buttons-query-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.each-radio-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
    font-size: 17px;
}

.each-radio-button-container input[type="radio"]:focus {
    /* outline: none; */
    box-shadow: none;
}

.book-a-call-popup-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.book-a-call-popup-content-left p {
    font-size: 17px;
    margin-bottom: 30px;
}

.book-a-call-popup-content-right p {
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: bold;
}

.query-text-area {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.react-calendar__navigation button:disabled {
    background-color: white !important;
}

.react-calendar__tile:disabled {
    background-color: #f6f6f6 !important;
    color: #ababab;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #209ef3 !important;
    color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #ffffff !important;
}

.react-calendar__tile--now {
    background-color: white !important;
    color: #209ef3 !important;
}

.book-a-call-popup-contact-info-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.book-a-call-popup-contact-info-right p {
    margin-top: 10px;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 600;
}

.book-a-call-popup-contact-info-right h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
}

.book-a-call-popup-contact-info-right input {
    display: flex;
    border-radius: 5px;
    width: 100%;
    flex: 1;
}

.green-message {
    background-color: #eafbf5;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.green-message p {
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

/*desktop*/

@media only screen and (max-width: 1350px) {

    .mid-video-container p {
        font-size: 3rem;
    }

}

@media only screen and (max-width: 1155px) {

    .mid-video-container p {
        font-size: 3rem;
    }

    .mid-video-container {
        width: 80vw;
    }

}

@media only screen and (max-width: 1035px) {


    .mid-video-container p {
        font-size: 2.7rem;
    }

    .mid-video-container {
        gap: 2%;
    }

    .products_video-homepage {
        min-width: 45vw;
    }

    .products_video-homepage iframe {
        width: 45vw;
        height: calc(45vw * 9 / 16);
    }

    .HeroText H1 {
        font-style: normal;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 6rem;
        padding-top: 10px;
        color: rgba(0, 0, 0, 0.86);
    }

    .mid-hero-text p {
        font-style: normal;
        font-weight: 300;
        font-size: 31px;
        line-height: 56px;
        padding-top: 10px;
        color: #00000089;
        max-width: 80vw;
    }

    .playstationTop img {
        width: 20vw;
        min-width: 120px;
        object-fit: contain;
    }

    .left-top-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20vh;
        width: 50vw;
        /*align-content: center;*/
        /*align-self: center;*/
        /*align-items: center;*/
        margin-left: 7%;
    }

    .bank-ss-container img {
        width: 30vw;
    }
}

@media only screen and (max-width: 800px) {
    .bank-ss-container img {
        width: 40vw;
    }

    .mid-video-container {
        flex-direction: column;
        margin-top: 15%;
        margin-bottom: 5%;
        width: 80vw;
        gap: 5%;
    }

    .mid-video-container p {
        font-size: 2.8rem;
        text-align: center;
        font-weight: 350;
        margin-bottom: 5%;
    }

    .products_video-homepage {
        display: flex;
        min-width: 60vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .products_video-homepage iframe {
        width: 60vw;
        height: calc(60vw * 9 / 16);
    }
}

/*tablets*/
@media only screen and (max-width: 700px) {
    .HeroText H1 {
        font-style: normal;
        font-weight: 400;
        font-size: 3rem;
        line-height: 6rem;
        padding-top: 10px;
        color: rgba(0, 0, 0, 0.86);
    }

    .mid-img-container img {
        width: 70vw;
    }

    .mid-full-img-container img {
        width: 87vw;
    }

    .mid-hero-text {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 20%;
        margin-bottom: 10%;
        align-self: center;
    }

    .products_video-homepage iframe {
        width: 70vw;
        height: calc(70vw * 9 / 16);
    }

    .book-a-call-main-listing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10%;
        padding-bottom: 10%;
        /* background-color: #ccedf4; */
        background-color: #ffffff;
        height: 100%;
        overflow: visible;
        width: 100vw;
    }

    .office img {
        width: 50%;
    }

    .right-section .buttons .book-call {
        width: 100%;
    }
}

/*small-tab*/
@media only screen and (max-width: 600px) {
    .carousel-div p {
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 56px;
        padding-top: 10px;
        color: #00000089;
    }

    .top-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        align-content: center;
        align-self: center;
    }

    .left-top-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20vh;
        width: 100%;
        align-content: center;
        align-self: center;
        align-items: center;
        margin-left: 0;
    }

    .HeroText {
        text-align: center;
        width: 90%;
        /*margin-bottom: 10%;*/
    }

    .HeroText H1 {
        font-style: normal;
        font-weight: 400;
        font-size: 3rem;
        line-height: 6rem;
        padding-top: 10px;
        color: rgba(0, 0, 0, 0.86);
    }


    .playstationTop {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7%;
        object-fit: contain;
    }

    .playstationTop img {
        width: 30vw;
        height: auto;
        min-width: 100px;
        object-fit: contain;
    }

    .home-page-top-right {
        /*display: flex;*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        /*width: 30vw;*/
        margin-top: 10vh
    }

    .top-right-img {
        margin-top: 50px
    }

    .top-right-img img {
        margin-top: 50px;
        height: 70vh;
    }

    .bank-carousel {
        width: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 10vh;
        /*margin-left: 8vw;*/
    }

    .products_video-homepage iframe {
        width: 60vw;
        height: calc(60vw * 16 / 9);
    }

    .gif-our-solution-container img {
        width: 90vw;
    }

    .book-a-call-popup-content {
        display: flex;
        align-self: center;
        flex-direction: column;
        align-content: center;
        color: #424242;
        background-color: #ffffff;
        max-width: 700px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .book-a-call-popup-content-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 30px;
        border-right: #42424224 1px solid;
    }
    .book-a-call-popup-content-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding-top: 0px;
    }

    .radio-buttons-query-options input {
        width: auto !important;
        height: auto !important;
    }
    .book-a-call-popup-mobile-right{
        display: flex;
        align-self: center;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    .capitalflo_back_arrow{
        font-size: 25px;
    }
    /* .book-a-call-popup{
        max-height: 90vh;
        align-self: center;
    } */
}

/*mobiles*/
@media only screen and (max-width: 500px) {
    .carousel-div p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 56px;
        padding-top: 10px;
        color: #00000089;
    }

    .bank-carousel {
        width: 25vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 10vh;
        /*margin-left: 8vw;*/
    }

    .footer-mid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .footer-mid-top-right {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        margin-left: 5%;
        /*margin-top: 20px;*/
        text-decoration: none;
        /*margin-left: 5%;*/
        padding-left: 0;
    }

    .footer-mid-top-right ul {
        display: flex;
        flex-direction: row;
        gap: 5vw;
        text-decoration: none;
    }

    .footer-mid-top-right a {
        color: #424242;
        cursor: pointer;
        text-decoration: none;
        font-size: 18px;
    }

    .footer-mid-right {
        display: flex;
        flex-direction: column-reverse;
        padding-right: 0;
        width: 100%;
    }

    .footersocial {
        display: flex;
        justify-content: flex-start;
        margin-left: 5%;
        margin-top: 8%;
    }

    .footer-mid-left {
        display: none;
    }

    .playstation {
        display: flex;
        margin-left: 5%;
        margin-top: 3%;
        overflow: hidden;
        gap: 20px;
    }

    .playstation img {
        width: 30vw;
        height: auto;
        object-fit: contain;
    }

    .linkedin {
        color: #0077B5;
        width: 80%;
    }

    .instagram {
        color: #E4405F;
        width: 80%;
    }

    .twitter {
        color: #424242;
        width: 75%;
    }

    .youtube {
        color: #FF0000;
        width: 80%;
    }

    .home-blog-subscribe form {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        gap: 0;
    }

    .home-blog-subscribe input {
        margin-top: 5%;
        margin-bottom: 5%;
        border-radius: 5px;
        min-width: 300px;
        /*max-width: 350%;*/
    }

    .home-blog-subscribe button {
        margin-top: 5%;
        margin-bottom: 5%;
        margin-top: 0;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
        color: #dedbdb;
        background: #004aad;
        max-width: 50%;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-right: 0;
        margin-bottom: 3%;
    }

    .footer-bottom-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        align-self: center;
        text-align: center;
    }

    .footer-bottom-left {
        width: 100%;
        margin-bottom: 3%;
        margin-left: 1%;
        margin-top: 1.5%;
    }

    .footer-bottom-right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        align-self: center;
        /*gap: 5%;*/
        width: 80%;
    }

    .footer-bottom-right ul li {
        list-style: none;
    }

    .footer-bottom-right ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        margin-bottom: 2%;
    }

    .footer-bottom-right a {
        text-decoration: none;
        color: #424242;
        font-size: 14px;
    }

    .products_video-homepage iframe {
        width: 70vw;
        height: calc(70vw * 16 / 9);
    }

}

@media only screen and (max-width: 464px) {
    .mid-video-container {
        margin-top: 20%;
        width: 85vw;
    }

    .mid-video-container p {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .bank-carousel {
        width: 40vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 10vh;
        margin-left: 5vw;
    }

    .playstationTop img {
        width: 40vw;
        height: auto;
        min-width: 100px;
        object-fit: contain;
    }

    .mid-img-container img {
        width: 70vw;
    }

    .mid-full-img-container img {
        width: 95vw;
    }
}

/*height*/
/*@media only screen and (max-height: 800px) {*/
/*    .home-page-top-right {*/
/*        !*display: flex;*!*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*        flex: 1;*/
/*        !*width: 30vw;*!*/
/*        margin-top: 20vh*/
/*    }*/
/*}*/
* {
    box-sizing: border-box;
}

body {
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
}

html {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faqs-webpage {
    width: 100vw;
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
}

.faq {
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 70vw;
    margin-top: 15vh;
}

.faq p {
    font-size: 20px;
    /*color: rgb(150, 150, 150);*/
}

.faq p {
    line-height: 1.38;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.faq h1 {
    font-weight: 400;
}

.faq h2 {
    line-height: 1.38;
    margin-top: 18pt;
    margin-bottom: 6pt;
    font-size: 25px;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000000;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.faq ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 5%;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    list-style: disc; /* Add this line */
    line-height: 2;
}

.faq li {
    color: black; /* Adjust the color as needed */
    list-style-type: disc; /* Change this to circle or square if desired */
    font-size: 16pt;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/*@media (max-width: 450px)  {*/


/*  .innerdate {*/
/*    font-size: 13px;*/
/*  }*/

/*  .innertime {*/
/*    font-size: 13px;*/
/*  }*/
/*  */
/*}*/

.card {
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
}

.modal {
    position: fixed;
    top: 12vh;
    left: 32%;
    width: 34%;
    z-index: 100;
    overflow: hidden;
}

/* PostWidget.css */
.post-widget-container {

    position: relative;
    top: 20px; /* Adjust this value as needed */
    background-color: #FFFFFF;
    /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);*/
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    width: 70%;
}


.post-widget-title {
    /*display: flex;*/
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
    /*border-bottom: 1px solid #ccc;*/
    padding-bottom: 0.5rem;
}

.recent-widget-item {
    display: flex;
    align-items: center;
    width: 55%;
    margin-bottom: 3rem;
    text-decoration: none;
    justify-content: center;

}

.post-widget-image {
    height: 5vh;
    /*height: auto;*/
    max-width: 150px;
    align-content: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
}

/* Add this CSS to your PostWidget.css file */
.post-widget-image img {
    height: 5vh;
    max-width: 150px;
    overflow: hidden;
}

/*.post-widget-image {*/
/*    width: 100%;*/
/*    height: auto;*/
/*    align-content: center;*/
/*    justify-content: center;*/
/*    display: flex;*/
/*}*/

/*.post-widget-image img {*/
/*    height: 100px;*/
/*    width: auto;*/
/*}*/


.post-widget-details {
    flex-grow: 1;
    margin-left: 1rem;
}

.commentsLengthBlue {
    display: flex;
    flex-direction: row;
}

.custom-line {
    width: 100%;
    border: none;
    height: 2px;
    background-color: black;
    margin: 10px 0;
}


.post-widget-date {
    display: flex;
    margin-top: 1vh;
    color: #888;
    font-size: 0.75rem;
}

.post-widget-link {
    display: flex;
    margin-bottom: .5vh;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    text-decoration: none;
    color: #333;
    font-weight: 600;
}

.post-widget-link:hover {
    text-decoration: underline;
}

.recentPostDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.excerptDetails {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    color: #666666;
    font-size: 1rem;

}

.seconConatainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.recentComments {
    width: auto;
}

.commentsLengthPostWidget {
    /*margin-left: 3vw;*/
    flex-direction: row;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    justify-content: center;
}

/*.recentPostExcerpt{*/
/*    margin-left: 30vw;*/
/*}*/
.recentPostTitle {
    width: 50%;
}

.view-all-title {
    & h3 {
        font-size: 24px;
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        color: #424242;
    }
}

.recentPostHeader {
    display: flex;
    flex-direction: row;
    align-content: center;
    vertical-align: center;
}

.view-all-recents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    width: 75vw;
    margin-bottom: 10px;
}

.view-all-recents a {
    text-decoration: none;
    color: #333;
    font-weight: 400;
}

/*.view-all-recents a:hover {*/
/*    text-decoration: underline;*/
/*}*/
.recent-widget-item :hover {
    .a {
        text-decoration: underline;
    }
}

.horizontal-line {
    border-width: 0.5px;
    border-color: rgba(211, 211, 211, 0.34);
    margin-top: 3rem;
}

@media (max-width: 600px) {
    .post-widget-image {
        width: 100%;
        height: auto;
        align-content: center;
        justify-content: center;
        display: flex;
    }

    .post-widget-image img {
        height: 100px;
        width: auto;
    }

    .recent-widget-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 55%;
        margin-bottom: 3rem;
        text-decoration: none;
    }
}

@media (max-width: 450px) {
    .post-widget-image {
        width: 100%;
        height: auto;
        align-content: center;
        justify-content: center;
        display: flex;
    }

    .post-widget-image img {
        height: 100px;
        width: auto;
    }

    .recent-widget-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 55%;
        margin-bottom: 3rem;
        text-decoration: none;
    }

    .recentPostDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .recentPostTitle {
        width: 90%;
    }

    .commentsLengthBlue {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
    }

    .post-widget-title {
        display: flex;
        font-size: 1.5rem;
        align-content: center;
        justify-content: center;
        margin-bottom: 1rem;
        font-weight: 600;
        align-self: center;
        /*border-bottom: 1px solid #ccc;*/
        padding-bottom: 0.5rem;
    }
}

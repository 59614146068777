.post-widget-container {
    position: relative;
    top: 20px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    width: 70%;
}

.post-widget-link {
    display: flex;
    margin-bottom: .5vh;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.2rem;
    text-decoration: none;
    color: #424242;
    font-weight: 600;
}

.post-widget-link:hover {
    text-decoration: underline;
}

.view-all-title {
    & h3 {
        font-size: 24px;
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        color: #424242;
    }
}

.viewAll {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    width: 70vw;
    margin-bottom: 10px;
}

.viewAll a {
    text-decoration: none;
    color: #333;
    font-weight: 400;
}

.viewAll a:hover {
    text-decoration: underline;
}

.featuredPostHeader {
    display: flex;
    flex-direction: row;
    align-content: center;
    vertical-align: center;
}


/*@media (max-width: 600px) {*/
/*    .recentPostDetails {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: space-between;*/
/*    }*/
/*}*/

.grid-container {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 70vw;
    height: auto;
}

.grid-item-header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.grid-item-info {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 3px;
    margin-top: 10px;
}

.grid-item-info p {
    margin-right: 10px; /* Adjust the margin as needed */
    font-size: 16px;
}

.grid-item {
    border: 0.5px solid rgb(128, 128, 128);
    border-radius: 25px;
    padding: 30px;
    transition: transform 0.3s ease;
    text-decoration: none;
    color: #424242;
}

.grid-item:hover {
    transform: scale(0.98);
    /*border: none;*/
    background-color: rgba(141, 141, 141, 0.07);
}

.grid-item img {
    width: 100%;
    height: auto;
}

.grid-item p {
    margin: 5px 0;
}

.grid-item h3 {
    margin: 10px 0;
    font-size: 22px;
}

@media (max-width: 600px) {
    .grid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        width: 70vw;
        height: auto;
    }
}

@media (max-width: 450px) {
    .grid-container {

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5vw;
        width: 70vw;
        height: auto;
        margin-left: 0px;
    }
}

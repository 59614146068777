* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

html {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.products__container {
    margin-top: 20vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 70vw;
    align-self: center;
}

.products__heading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.products__heading_container {
    border-bottom: 0.5px solid #4242421e;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 5%;
}

.products__heading_container p {
    font-size: 20px;
    margin-bottom: 2%;
    margin-top: 1%;
    text-align: left;
    color: #424242;
    line-height: 1.5;
    /* Increase the line height */
    word-spacing: 1.3px;
    /* Increase the word spacing */
    letter-spacing: 0.8px;
    font-weight: 320;
}

.products__heading_container h1 {
    color: #424242;
    font-size: 40px;
    font-weight: 430;
    text-align: center;
    letter-spacing: 0.8px;

}

.products__heading_container a {
    color: #387ed1;
    text-decoration: none;
    font-weight: 500;
}

.products_list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 5%;
}

.video_text_product {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20%;
    gap: 20px
}

.products_video-container_video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.products_video-container_text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 30%;
    margin-bottom: 20px;
}

.products_video-container_text p {
    font-size: 17px;
    margin-bottom: 1%;
    margin-top: 1%;
    text-align: left;
    color: #424242;
    font-weight: 320;
    line-height: 1.5;
    /* Increase the line height */
    word-spacing: 1.3px;
    /* Increase the word spacing */
    letter-spacing: 1px;
}

.products_video-container_text h2 {
    color: #424242;
    font-size: 35px;
    font-weight: 430;
    text-align: left;
    margin-bottom: 20px;
    letter-spacing: 0.8px;
}

.products_video-container_text a {
    color: #387ed1;
    text-decoration: none;
    font-weight: 500;
}

.products_bottom_heading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.products_bottom_heading_container {
    border-bottom: 0.5px solid #4242421e;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 5%;
}

.products_bottom_heading_container p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 1%;
    margin-top: 1%;
    text-align: left;
    color: #424242;
    line-height: 1.5;
    /* Increase the line height */
    word-spacing: 1px;
    /* Increase the word spacing */
    letter-spacing: 0.6px;
}

.products_bottom_heading_container h2 {
    color: #424242;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    margin-top: 4%;
    letter-spacing: 0.8px;
}

.products_bottom_heading_container a {
    color: #387ed1;
    text-decoration: none;
    font-weight: 500;
}

.products_bottom_heading_container button {
    margin-top: 1%;
    margin-bottom: 3%;
    background-color: #387ed1;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    border-radius: 3px;
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;
}

.download-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-top: 3%;
}

.download-buttons img {
    width: 200px;
    height: auto;
    margin: 0 10px;
}

.products_video-features iframe {
    height: 480px;
    width: 270px;
}

.products_video-container_video iframe {
    width: 480px;
    height: 270px;
}

@media (max-width: 1100px) {
    .products_video-features iframe {
        height: 480px;
        width: 270px;
    }

    .products__container {
        width: 80vw;
    }
}

@media (max-width: 900px) {
    .products__container {
        width: 90vw;
    }
}

@media (max-width: 800px) {
    .video_text_product {
        display: none;
    }

    .video_first_then_text_conatiner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: 20%;
        gap: 20px
    }

    .text_first_then_video_conatiner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20%;
        gap: 20px
    }

    .products_video-container_text {
        max-width: 80%;
        align-items: center;
    }

    .products_video-container_text p {
        text-align: center;
    }

    .products_video-container_text h2 {
        text-align: center;
    }

    .products_bottom_heading_container p {
        text-align: center;
    }
}

@media (max-width: 600px) {
    .products_video-features iframe {
        width: 50vw;
        height: calc(50vw * 16 / 9);
        margin-top: 20px;
        margin-bottom: 20px;
    }

}

@media (max-width: 550px) {

    .video_first_then_text_conatiner .text_first_then_video_conatiner {
        margin-bottom: 30%;
    }

    .products__heading_container p {
        text-align: center;
        align-content: center;
    }

    .download-buttons img {
        width: 40vw;
    }

    .products_video-container_video iframe {
        width: 480px;
        height: 270px;
    }
}

@media (max-width: 500px) {
    .products_video-features iframe {
        width: 60vw;
        height: calc(60vw * 16 / 9);
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .products_video-container_video iframe {
        width: 90vw;
        height: calc(90vw * 9 / 16);
    }

}

@media (max-width: 430px) {
    .products_video-features iframe {
        width: 80vw;
        height: calc(80vw * 16 / 9);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
/* CommentsForm.css */

.commentsFormContainer {
    background-color: #fff;
    /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);*/
    border-radius: 8px;
    padding: 4px;
    margin-top: 20px;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 80%
}

.commentsFormContainer h3 {
    font-size: 1.5rem;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
    margin-bottom: 16px;
}

.form_name {
    font-size: 18px;
    font-weight: 550;
    margin-bottom: 0.5rem;
    text-align: left;
    align-self: flex-start;
}


.form_name_container {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.commentsFormContainer textarea,
.commentsFormContainer input[type="text"],
.commentsFormContainer input[type="email"] {
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #FFFFFF;
    color: #424242;
    outline: none;
    font-family: Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;

}

.commentsFormContainer textarea {
    width: 100%;
}

/*.commentsFormContainer input[type="text"],*/
/*.commentsFormContainer input[type="email"] {*/
/*    width: 100%;*/

/*}*/

.commentsFormContainer textarea {
    height: 160px;
}

.commentsFormContainer label {
    font-size: 15px;
    color: #777;
    cursor: pointer;
    /*padding-right: 20vw;*/
}

.commentsFormContainer .text-red-500 {
    font-size: 0.75rem;
    color: #e53e3e;
}

.commentsFormContainer button {
    background-color: #004aad;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    padding: 12px 24px;
    border: none;
    border-radius: 999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5vh;
    margin-bottom: 4vh;
    /*margin-right: ;*/
}

.commentsFormContainer button:hover {
    background-color: #0357c7;
}

.commentsFormContainer .text-green-500 {
    font-size: 1rem;
    color: #48bb78;
    font-weight: 600;
    float: right;
    margin-top: 12px;
}

.checkBoxblog {
    /*padding-right: 30%;*/
    width: auto;
    height: 10%;
}

.lableblog {
    /*padding-right: 120%;*/
}


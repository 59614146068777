* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

#sign-out-all-devices {
    width: 100%;
    height: 100%;
    align-items: left;
}

#checkbox {
    width: 4%;
    height: 20%;
    align-items: left;
    scroll-behavior: auto;
}

.resetpass {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 1200px;
}

.resetpass h1 {
    color: rgb(94 94 94);
    margin-top: 100px;
}

.resetpass form {
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.resetFields {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.resetFields label {
    padding: 10px 0px 5px 0px;
    color: #004AAD;
}

.resetpassSubmit {
    margin-top: 20px;
    color: #fff;
    background-color: #004AAD;
    cursor: pointer;
}

.Resetpassanimation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.messageofreset {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ErrMsg {
    font-size: 13px;
    color: #ff4747;
    width: 334px;
}

.showIcon {
    transform: translateX(-25px);
    cursor: pointer;
}

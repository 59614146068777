.top-header-cover {
    display: flex;
    align-content: center;
    display: flex;
    align-self: center;
    /* align-items: normal; */
    justify-content: center;
    flex: 1;
    width: 100vw;
    flex-direction: column;
    background-color: white;
    position: fixed;
    z-index: 1500;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    /* horizontal-offset vertical-offset blur-radius shadow-color */
}

.header-container {
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    max-width: 1400px;
    width: 100vw;
}

.capitalflo-logo img {
    width: 20vw;
    height: auto;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 5%;
}

.center-content a {
    color: #6f6f6f;
    font-size: 20px;
    margin: 0;
    padding-left: 13px;
    text-decoration: none;
}

.header-center-content {
    min-width: 400px;
    /*flex: 1*/
}

.center-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
}

.header-right-content {
    display: flex;
    flex: 0.5;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2%;
    gap: 7%;
}

.header-right-content .signup,
.header-right-content .signin {
    display: inline-block;
    padding: 10px 22px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    /*border: none;*/
    transition: background-color 0.3s ease;
}

.header-right-content .signup {
    background-color: #004aad;
    color: white;
}

.header-right-content .signup:hover {
    background-color: #003366;
}

.header-right-content .signin {
    background-color: white;
    color: #004aad;
    border: 3px solid #ececec;
    /*margin-left: 7%;*/
}

.header-right-content .signin:hover {
    background-color: #f2f2f2;
}

.dropdown-icon {
    display: inline;
    cursor: pointer;
}

.dropdown-icon img {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 22px;
    height: auto;
    /* margin-left: 10px; */
    /* margin-right: 5vw; */
}

.mobdrops {
    display: none;
}

/* .desktop-drops {
    background-color: #ffffff;
    width: -moz-fit-content;
    width: 100%;
    height: 438px;
    color: #000000c9;
    z-index: 10000;
    position:fixed;
    top: 20vh;
    display: flex;
    flex-direction: column;
    margin-left: 2px;
    justify-content: left;
    margin-top: 90px;
    margin-bottom: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */
.desktop-drops {
    background-color: #1b1b1bea;
    width: -moz-fit-content;
    width: 230px;
    margin-right: 5%;
    color: #424242;
    display: flex !important;

    position: fixed;
    top: 120px;
    border-radius: 20px;

    flex-direction: column;
    justify-content: right;
    align-items: flex-end;
    align-self: flex-end;
    margin-bottom: 12px;
    box-shadow: rgba(100, 100, 111, 0.164) 0px 7px 29px 0px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.desktop-drops a {
    text-decoration: none;
    padding-top: 9px;
    color: #ffffff;

}

.desktop-drop-links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    align-self: center;
    flex: 1;
    width: 100%;
    max-width: 1400px;
}

.eachdrop-desktop {
    font-size: 20px;
    padding: 25px;
    cursor: pointer;
}


@media (max-width: 920px) {
    .signin {
        display: none !important;
    }

    .header-center-content {
        display: none;
    }

    .dropdown-icon {
        display: inline;
    }

    .dropdown-icon img {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 27px;
        height: auto;
        margin-left: 2vw;
        margin-right: 5vw;
    }

    .capitalflo-logo img {
        width: 30vw;
    }

    .desktop-drops {
        display: none !important;
    }

    .mobdrops {
        background-color: #ffffff;
        width: -moz-fit-content;
        width: 100%;
        /* height: 438px; */
        color: #000000c9;
        z-index: 10000;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 2px;
        justify-content: left;
        /* margin-top: 90px; */
        /* margin-bottom: 12px; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .mob-link-options {
        display: flex;
        flex-direction: row;
        align-self: center;
        width: 100vw; 
    }

    .mob-link-left-options {
        border-right: #42424224 1px solid;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        padding-left: 5%;
        padding-bottom: 5%;

    }

    .mob-link-right-options {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        padding-left: 5%;
    }

    .eachdropmob a {
        text-decoration: none;
        color: #424242;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
    }

    .eachdropmob {
        font-size: 20px;
        /* padding: 25px; */
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
        vertical-align: middle;

    }

    .book-a-call-button {
        display: flex;
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 10px 16px;
        border-radius: .5rem;
        background: linear-gradient(180deg, #60acce, #54a8cf);

    }

    .book-a-call a {
        font-size: 20px;
        text-decoration: none;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
    }

    .drop-sign {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2%;
        padding-top: 5%;
        padding-bottom: 5%;
        /* flex: 1; */
        /*padding-top: 2%;*/
        box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.1);
        /* Add a shadow to the top border */
        /*gap: 10px;*/
    }

    .drop-sign .sign-up,
    .drop-sign .sign-in {
        display: inline-block;
        padding: 10px 22px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        border: none;
        transition: background-color 0.3s ease;
    }

    .drop-sign .sign-up {
        background-color: #004aad;
        color: white;
    }

    .drop-sign .sign-up:hover {
        background-color: #003366;
    }

    .drop-sign .sign-in {
        background-color: white;
        color: #004aad;
    }

    .drop-sign .sign-in:hover {
        background-color: #f2f2f2;
    }

    .header-right-content {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2%;
        gap: 0%;
    }
}

@media (max-width: 800px) {
    .capitalflo-logo img {
        width: 35vw;
    }

    /* .mobdrops {
        margin-top: 90px;
    } */
}

@media (max-width: 650px) {
    .capitalflo-logo img {
        width: 35vw;
    }

    /* .mobdrops {
        margin-top: 80px;
    } */
}

@media (max-width: 530px) {
    .capitalflo-logo img {
        width: 50vw;
    }

    /* .mobdrops {
        margin-top: 80px;
    } */

    /*}*/
    /*@media (max-width: 450px) {*/
    /*.capitalflo-logo img {*/
    /*    width: 40vw;*/
    /*}*/
    .dropdown-icon img {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 22px;
        height: auto;
        margin-left: 3.5vw;
        margin-right: 3vw;
    }

    .header-right-content .signup,
    .header-right-content .signin {
        padding: 8px 13px;
        font-size: 16px;

    }
}
.upperFh {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 150px;
    margin-bottom: 10px;
}
.financialHArrow {
    display: block;
    position: absolute;
    right: 0;
    top: 200px;
    font-size: 15px;
    left: 5vw;
}

.upperFh img {
    width: 70px;
    height: 70px;
}

.upperFh p {
    line-height: 64px;
    font-size: 57px;
    overflow: hidden;
}
.financial-form {
    width:45vw;
}
.outerFHFlex {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;
    align-items: center; /* Ensure all items are centered */
    justify-content: space-between;
}

.imgFHContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.imageFH {
    height: 50px;
    width: 50px;
}
.tickImageFH {
    height: 25px;
    width: 25px;
    fill: green;
    font-weight: 800;
    animation: scaleAnimation 0.5s ease-in-out;
    object-fit: contain;
}
.warningImageFH {
    font-size:30px;
    color: #f5ad05;
    font-weight: 800;
    animation: scaleAnimation 0.5s ease-in-out;
}
.dangerImageFH {
    font-size:30px;
    color: #ab0505;
    font-weight: 800;
    animation: scaleAnimation 0.5s ease-in-out;
}

.middleFHContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.longParaFHContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 39vw;
    padding-right: 5px;
}
.leftMostContainer {
    display: flex;
    flex-direction: row;
}
.headingFH {
    font-size: 30px;
    color: #424242;
    margin: 0; /* Remove default margin */
}

.labelFH {
    font-size: 20px;
    color: #424242;
    margin: 0;
    font-weight: 400
}

.arrowFHContainer {
    display: flex;
    align-items: flex-end;
}

.arrowFH {
    margin-top: 5px;
    font-size: 30px;
    color: #424242;
}

/*emergency*/
.emergencyFhFlex{
    margin-bottom:30px;
    display:flex;
    gap:10px;
    justify-content:space-between;
}
.emergencyFhLeft {
    font-size:22px;
}
.FHArrow {
    display:flex;

}

.info-popupFH {
    font-size: 20px;
    color: #000;
}

.info-popupFH {
    padding: 15px;
    position: absolute;
    top: 28vh;
    left: 70vw;
    height: auto;
    max-width: 300px;
    border-radius: 5px;
    background-color: #edf0ee;
    z-index: 1000;
    display: none;
}

.info-popupFH.visible {
    display: block;
}

.info-popupFH.visible p {
    font-size: 20px;
    color: #4f4d4d;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 300;
}

/*Financial feedback from the result*/
.resultFeedback {
    margin-bottom:30px;

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.financialHArrow a {
    text-decoration: none;
    color: #004AAD;
}
.arraowsFh {
    width: 40px;
    color: #000;
}
@keyframes scaleAnimation {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
.emergencyFhRight {
    height:33px;
    font-size: 22px;
    width:170px;
    min-width: 130px;
}
.feedbackFh {
    display:flex;
    justify-content:space-between;
    margin-bottom: 30px;
}
.feedbackFhLeft {
    font-size:22px;
}
.feedbackFhRight {
    border:none;
    font-size:20px;
}
.resultFH {
    display:flex;
    flex-direction:row;
    justify-content:center;
}
/*annual debt service*/
.annualFhLeft {
    font-size:22px;
}
.annualFhRight {
    height:33px;
    font-size: 22px;
    width:170px;
}
@media (max-width: 320px) {
    .upperFh p {
        font-size:25px
    }
    .upperFh img {
        width: 50px;
        height: 50px;
    }
    .financial-form{
        width: 80vw;
    }
    .headingFH{
        font-size: 22px;
    }
    .labelFH {
        font-size: 15px
    }
    .longParaFHContainer {
        max-width: 70vw;
    }
    .imageFH {
        width: 40px;
        height: 40px;
    }
    .arrowFH {
        min-width: 10px;
        max-width: 13px;
    }
    /*}*/
    /*.financialHArrow {*/
    /*    display: block;*/
    /*    position: absolute;*/
    /*    top: 100px;*/
    /*    font-size: 15px;*/
    /*    left: 5vw;*/
    /*}*/
}
@media (max-width: 425px) {
    .annualFhLeft {
        font-size:22px;
        object-fit: contain;
        overflow: visible;
    }
    .upperFh p {
        font-size:25px;
        max-width: 60vw;
        max-height: 20vh;
        text-align:center;
    }
    .upperFh img {
        width: 50px;
        height: 50px;
    }
    .financial-form{
        width: 90vw;
    }
    .headingFH{
        font-size: 22px;
    }
    .labelFH {
        font-size: 15px
    }
    .longParaFHContainer {
        max-width: 70vw;
    }
    .arrowFH {
        min-width: 10px;
        max-width: 13px;
    }
    .tickImageFH {
        height: 13px;
        width: 13px;
    }
    .dangerImageFH {
        font-size: 14px;
    }
    .warningImageFH {
        font-size: 14px;
    }
    .emergencyFhRight {
        height:33px;
        font-size: 22px;
        width:130px
    }
    .annualFhRight {
        height:33px;
        font-size: 22px;
        width:130px;
    }
    .feedbackFh {
        display:flex;
        flex-direction:column;
    }
    .financialHArrow {
        display: none !important;
        position: absolute;
        top: 200px;
        font-size: 15px;
        left: 10vw;
    }
}
@media (min-width: 425px) and (max-width: 768px) {
    .financial-form{
        width: 90vw;
    }
    .longParaFHContainer {
        max-width: 80vw;
    }
    .emergencyFhRight {
        height:33px;
        font-size: 22px;
        width:130px
    }
    .annualFhRight {
        height:33px;
        font-size: 22px;
        width:130px;
    }
    .financialHArrow {
        display: none !important;
        position: absolute;
        top: 60px;
        font-size: 15px;
        left: 10vw;
    }
    .arraowsFh {
        width: 30px;
        color: #000;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .emergencyFhRight {
        height:33px;
        font-size: 22px;
        width:130px
    }
    .annualFhRight {
        height:33px;
        font-size: 22px;
        width:130px;
    }
}
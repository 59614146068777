* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow-y: hidden; */
}

.mainplanheading {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    padding-top: 37px;
    width: 1101px;
    margin-left: 70px;
    color: black;
    margin-top: 100px;
    padding-bottom: 100px;
}
.bottomSpace {
    padding-bottom: 350px;
}

.mainplanheading h2 {
    font-weight: 350;
    padding-right: 120px;
    font-size: 40px;
}

.totalimg {
    width: 100px;
    height: 100px;
}

.mainplanheading img {
    width: 107px;
    height: 113px;
}

.addbudget {
    width: 51px;
    height: 50px;
    cursor: pointer;

}

.reportheading {
    font-size: 25px;
    color: #004AAD;
    padding-left: 10px;
}

.budgetoptionss {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin-left: 400px;
}

.budgetoptionss p {
    font-size: 32px;
}

.budgetoptionss p {
    width: 200px;
}

.middleoptionss {
    margin-bottom: 77px;
}

.reference {
    width: 1101px;
    display: flex;
    font-size: 29px;
    font-weight: 500;
    align-items: center;
    justify-content: left;
    padding-top: 18px;
    padding-bottom: 25px;
    margin: 0 auto;
    gap: 5px;
    color: rgb(207, 2, 2);
}

.outerreference {
    width: 1101px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 100px;
    padding-bottom: 22px;
}

.income {
    display: flex;
    justify-content: center;
    color: #004AAD;
    font-size: 25px;
    font-weight: 600;
}

.referenceheading {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    padding: 20px 0px 10px 0px;
}

.referencehead {
    color: #004AAD;
    display: flex;
    font-size: 28px;
    font-weight: 600;
    justify-content: left;
    padding: 26px 0px 0px 212px;
}

.sidereference {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.fixedreferencelist p {
    font-size: 18px;
    color: #000;
    font-weight: 200;
    padding: 8px;
}

.variablereferencelist p {
    font-size: 18px;
    color: #000;
    font-weight: 200;
    padding: 8px;
}

.AddExp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AddExp p {
    font-size: 30px;
}

.AddExp img {
    width: 30px;
    height: 30px;
}

.maintable {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-self: center;
    margin-left: 27px;
    padding-bottom: 10px;
}

.maintable p:last-child {
    padding-left: 175px;
    font-size: 25px;
}

.FirstRow {
    padding: 20px;
}

.FirstRow .mainFirstRow {
    width: 58px;
    height: 30px;
    margin-left: -16px;
    padding-top: 2px;
}

table {
    padding-top: 20px;
}

.table-container {
    display: inline-flex;
    width: 50%;
    margin: 0px;
    padding-left: 20px;
}

.IncomeTable {
    color: #004AAD;
    font-weight: 600;
    font-size: 25px;
}

.integer-input {
    width: 150px;
    height: 40px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
}

.integer-input[type="text"]:focus {
    width: 150px;
    height: 40px;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
}

.wrap {
    display: flex;
    gap: 20;

}

.table-container-massive {
    display: flex;
    width: 55%;
    margin: 0px;
    padding-left: 20px;
}

.income-table {
    display: flex;
    width: 100%;
    margin: 0px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.integer-input {
    font-size: 20px;
}

/* .saveButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-right: 270px;
  padding-top: 470px;
} */
.saveButtonBelow {
    padding-top: 50px;
    padding-right: 900px;

}

/* .saveButtonBelow button {
  border: 1px solid white;
  border-color: darkgray;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */
/* .saveButton button {
  border: 1px solid white;
  border-color: darkgray;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
} */
.delete-cross {
    cursor: pointer;
    font-size: 0.9rem;
    position: absolute;
    top: 5px;
    left: 5px;
    color: black;
}

.Total-Expense {
    display: flex;
    width: 100%;
    margin: 0px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 40px;
}
.MI_ME {
    display: flex;
    width: 100%;
    padding-left: 20px;
}

.sumTotal {
    font-weight: bold;
}

.info-PB {
    /* margin-left: 120px; */
    /* margin-bottom: 12px; */
    position: absolute;
    top: 15vh;
    left: 65vw;
    max-width: 300px;
    contain: content;
    height: auto;
    padding:10px;
    border-radius: 5px;
    background-color: #efefef;
    z-index: 1000;
    display: none;
}


.info-PB.visible {
    display: block;
}

.info-PB.visible p {
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 300;
}

.info-PB {
    font-size: 16px;
    color: #000;
}

.info-popup {
    /* margin-left: 120px; */
    /* margin-bottom: 12px; */
    position: absolute;
    top: 49vh;
    left: 24vw;
    width: 200px;
    height: 39px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}


.info-popup.visible {
    display: block;
}

.info-popup.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-weight: 300;
}

.info-popup {
    font-size: 16px;
    color: #000;
}

.info-MI {
    /* margin-left: 120px; */
    /* margin-bottom: 12px; */
    position: absolute;
    top: 169vh;
    left: 38vw;
    width: 200px;
    height: 39px;
    border-radius: 5px;
    background-color: #eae9e9;
    z-index: 1000;
    display: none;
}


.info-MI.visible {
    display: block;
}

.info-MI.visible p {
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-weight: 300;
}

.info-MI {
    font-size: 16px;
    color: #000;
}

.sticky-containerPlan {
    position: absolute;
    position: absolute;
    top: 10px;
    right: 10px;
    padding-right: 270px;
    padding-top: 470px;
    /* display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 100px; */
}

.savedButtonPlan {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #004aad;
    padding: 12px;
    color: #fff;
    margin-right: 20%;
    width: 150px;
}

.savedButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #004aad;
    padding: 12px;
    color: #fff;
    margin-right: 20%;
    width: 150px;
}

.savebackgroundPlan {
    width: fit-content;
    background-color: #0000002e;
    position: absolute;
    height: 100px;
    padding: 10px;
    z-index: 100;
    top: 105px;
    right: 170px;
    display: flex;
    position: fixed;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    /* display: block;
    width: fit-content;
    height: 100px;
    padding: 10px;
    z-index: 100;
    background-color: #fff;
    /* position: fixed; */
}

/* ... existing CSS styles ... */

/* Add this CSS to apply the overlay effect to the planbudget section when the popup is open */
/* .planbudget.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as desired */
/* z-index: 1; Make sure the overlay is above other content */
/* } */
/* body.overlay {
  overflow: hidden; */
/* } */

/* ... more CSS styles ... */



/** {*/
/*    margin: 0px;*/
/*    padding: 0px;*/
/*    box-sizing: border-box;*/
/*}*/

/*body {*/
/*    font-family: 'Lato', sans-serif;*/
/*}*/

/*.postloghead {*/
/*    margin-top: 10px;*/
/*    margin: 0 auto;*/
/*    height: 100px;*/
/*    width: 1200px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    overflow: hidden;*/
/*}*/

/*!* Logheader.css *!*/
/*!* Logheader.css *!*/


/*!* .postloghead {*/
/*  top: 0;*/
/*  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);*/
/*  height: 73px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  overflow: hidden;*/
/*  position: absolute;*/
/*  left: 0;*/
/*  right: 0;*/
/*  padding: 0 100px;*/
/*} *!*/

/*!* Add any other styles you need for your header components *!*/
/*.logolog {*/
/*    width: 250px;*/
/*    height: 100px;*/
/*    display: flex;*/
/*    align-items: flex-start;*/
/*}*/

/*.logolog img {*/
/*    height: 80px;*/
/*    width: 230px;*/
/*}*/

/*.servicehome {*/
/*    padding: 104px 100px 100px 100px;*/
/*}*/

/*.servicehome li {*/
/*    list-style: none;*/
/*    cursor: pointer;*/
/*}*/

/*.icun {*/
/*    width: 10px;*/
/*    height: 10px;*/
/*}*/

/*.profilelog {*/
/*    position: absolute;*/
/*    top: 25px;*/
/*    left: 1254px;*/
/*}*/

/*.fixed-header {*/
/*    top: 0;*/
/*    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);*/
/*    height: 68px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    overflow: hidden;*/
/*    position: fixed;*/
/*    left: 0;*/
/*    right: 0;*/
/*    padding: 0 100px;*/
/*    background-color: #ffffff;*/
/*    z-index: 100;*/
/*}*/

/*.logolog {*/
/*    width: 250px;*/
/*    height: 100px;*/
/*    display: flex;*/
/*    align-items: flex-start;*/
/*}*/

/*.logolog img {*/
/*    height: 80px;*/
/*    width: 230px;*/
/*}*/

/*.servicehome {*/
/*    padding: 104px 100px 100px 100px;*/
/*}*/

/*.servicehome li {*/
/*    list-style: none;*/
/*    cursor: pointer;*/
/*}*/

/*.profilelog {*/
/*    top: 25px;*/
/*    left: calc(100% - 150px);*/
/*}*/


/*!* Media query for mobile responsiveness *!*/
/*@media (max-width: 450px) {*/

/*    .postloghead {*/
/*        height: auto;*/
/*        display: flex;*/
/*        flex-direction: row;*/
/*        align-items: center;*/
/*        gap: 50%;*/
/*        width: 450px;*/
/*    }*/

/*    .HomeNone {*/
/*        display: none;*/
/*    }*/

/*    .logolog {*/
/*        display: flex;*/
/*        margin-bottom: 0px;*/
/*        align-items: center;*/
/*        width: 150px;*/
/*        !* justify-content: flex-start; *!*/
/*    }*/

/*    .logolog img {*/
/*        width: 150px;*/
/*        height: auto;*/
/*    }*/

/*    .servicehome {*/
/*        padding: 0px;*/
/*    }*/

/*    .servicehome li {*/
/*        margin: 5px 0;*/
/*        text-align: center;*/
/*    }*/

/*    .icun {*/
/*        width: 10px;*/
/*        height: 10px;*/
/*    }*/

/*    .servicehome li {*/
/*        font-size: 20px;*/
/*    }*/

/*    .profilelog {*/
/*        position: static;*/
/*        top: auto;*/
/*        left: auto;*/
/*        margin-top: 10px;*/
/*    }*/

/*    .fixed-header {*/
/*        top: 0;*/
/*        width: 450px;*/
/*        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);*/
/*        height: 68px;*/
/*        display: flex;*/
/*        align-items: center;*/
/*        overflow: hidden;*/
/*        position: fixed;*/
/*        left: 0;*/
/*        right: 0;*/
/*        padding: 0 0px;*/
/*        background-color: #ffffff;*/
/*        z-index: 100;*/
/*    }*/
/*}*/


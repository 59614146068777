* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.termHead {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    font-size: 25px;
}

.bottomSpace {
    height: 350px;
}

.lasttag {
    width: 100%;
    display: flex;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.termContent {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0px 40px 0px;
    text-align: justify;
}

.termContent h3 {
    padding: 0px 10px 10px 0px;
}

.termContent h4 {
    padding: 5px 5px 10px 0px;
    font-size: 22px;
}

.termContent p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
}

@media (max-width: 450px) {
    .termContent {
        width: 450px;
        padding: 50px 93px 40px 42px;
        text-align: justify;
    }

    .termHead {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        font-size: 20px;
    }
}

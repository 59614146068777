/* Budget.css */
.middleoptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 150px;
    margin-top: 130px;
    width: 1200px;
}

.bottomSpaceBudget {
    height: 300px;
    color: #000;
}

.budgetoptions {
    display: flex;
    align-items: center;
    gap: 10px;
    /* padding-bottom: 10px; */


}

.budgetoptions p {
    font-size: 32px;
    width: 200px;

}

.addbudget {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 350px;
}

.mainbudget {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-left: 100px;
    margin-top: 60px;
}

.budgetdetails {
    width: 962px;
    height: fit-content;
    text-align: center;
    position: absolute;
    top: 400px;
    left: 25%;
    padding-bottom: 350px;
}


.budgetdetails th {
    width: 238px;
    font-size: 25px;
    text-align: start;
    color: #004AAD;
}

.budgetdetails td {
    text-align: start;
    padding-top: 22px;
    width: 200px;
}

.totalimg img {
    width: 51px;
    height: 52px;
}

.totalimg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
}

tbody {
    font-size: 20px;
}

thead {
    padding-bottom: 10px;
}

.outerbudgetin {
    display: inline-block;
    width: 100%;
    margin-bottom: 101px;
}

.budgetin img {
    width: 43px;
    border-radius: 21px;
    padding: 10px;
    height: 44px;
    background-color: #def6f6;
    align-items: center;
    justify-content: center;
}

.budgetin {
    display: flex;
    padding-top: 120px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.budgetin p {
    font-size: 25px;
    color: #757678;
}

.budgetbottombox {
    width: 836px;
    height: 130px;
    margin-bottom: 10px;
    margin-top: 20px;
    border: 1px solid #38c8c0;
}

.budgetdescription {
    color: #6B6B6C;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
}

.budgetdescription p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 17px;
}

.indicatingbackground {
    height: 23px;
    width: auto;
    background-color: #b1b6bc;
}

.indicatingbackground span {
    height: 23px;
    /* transform: translateX(50%); */
    background-color: aqua;
}

.innertab {
    background-color: #004AAD;
    /* width: 50%; */
    position: relative;
    /* width: auto; */
    height: 23px;
    transform: translateX(-100%);
}


.progress {
    background-color: #d8d8d8;
    border-radius: 0px 5px 5px 0px;
    position: relative;
    margin: 15px 0;
    height: 16px;
    width: 810px;
}

.progress-done {
    /* background: linear-gradient(to left, #F2709C, #FF9472);
	box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C; */
    background: linear-gradient(90deg, #09A37D 3.73%, rgba(53, 206, 179, 0.61) 95.02%);
    border-radius: 5px 0px 0px 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
}

.innerboxContent {
    padding: 13px;
}

.amountleft {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .indicator{
    width: '20px';
    background-color: tomato;
} */
/* .indicator{
    width: 836px;
    height: 10px;
    background-color: rgb(7, 171, 204);
}
.indicatingbackground{
    width: 836px;
    height: 10px;
    background-color: rgb(149, 167, 171);
}
.mainindicator{
    text-align: center;
    width: 800px;
    position: absolute;
    left: 20%;
} */


.back-button {
    cursor: pointer;
}

.back-button img {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    /* margin-right: 200px; */
}

/* Budget.css */
/* ... */

/* Budget.css */

.category-cell {
    text-align: left;
}

.category-cell-content {
    display: flex;
    align-items: center;
}

.category-image {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    margin-right: 8px; /* Adjust as needed */
}

.plan-budget-link {
    margin-top: 100px; /* Adjust the margin as needed */

}

.planbudgetText {
    text-decoration: none;
    color: #004aad;; /* Adjust the color as needed */
    font-size: 20px;
    /* padding-right: 600px; */
}

/* Budget.css */
.shifted-table-container {
    padding-right: 200px; /* Adjust the value as needed */
}

/*.editpopupmodel {*/
/*  height: 672px;*/
/*  overflow-y: scroll;*/
/*}*/
/*.completemodal{*/
/*  z-index: 1000;*/
/*}*/
.editoverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.closingbtn {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
}

.editbackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
}

.editbackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    /* Lowered the z-index value */
    background: rgba(0, 0, 0, 0.75);
}


.editpopupmodel p {
    font-size: 27px;
    position: absolute;
    font-weight: 600;
    height: 84px;
    padding-top: 36px;
    width: 100%;
    top: 0px;
    background: #fff;
    left: 42px;

}

.deletebtn {
    width: 37px;
    margin-left: 365px;
    margin-top: 33px;
    color: #000;
    /*display: absolute;*/
    /* height: 135px; */
    height: 5%;
    cursor: pointer;
}

.editcategory {
    display:flex;
    flex-direction: column;
    padding-top: 90px;
    padding-left: 57px;
    font-weight: 600;
    font-size: 20px;
    color: #004AAD;
    z-index: 1;
}

.editaddcategory {
    width: 60px;
    margin-left: 10px;
    /*margin: ;*/
    border-radius: 9px;
    height: 60px;
    background-color: #fbfbfb1f;
    display: flex;
    border: 1px solid #004AAD;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.editaddcategory img {
    width: 49px;
    height: 56px;
}

.addiconcategory {
    width: 44px;
    height: 95px;
}

.categoryimages h2 {
    padding-top: 5px;
    padding-left: 46px;
    font-weight: 600;
    font-size: 20px;
    color: #004AAD;
}

.imagelistout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 36px;
    padding-right: 34px;
    margin-top: 11px;
    margin-bottom: 65px;
}

.eachimage {
    width: 100px;
    height: 102px;
    border: 1px solid #004AAD;
    border-radius: 5px;
    margin: 5px;
}

.insideicon {
    width: 101px;
    height: 64px;
    color: #004AAD;
}

.eachimage h3 {
    font-size: 12px;
    display: flex;
    width: 84px;
    padding-left: 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.eachimage img {
    width: 79px;
    padding-left: 19px;
    padding-top: 10px;
    height: 66px;
}

.editcategoryForm {
    /* border: 1px solid #000;  */
    width: 400px;
    margin: 0 auto;
    padding-top: 32px;
    /* padding-bottom: 50px; */
}

.editnamelabel {
    color: #004AAD;
    font-weight: 600;
}

form input {
    color: #004AAD;
}

.editlabeled {
    padding-top: 10px;
}

.editcategorybtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 363px;
    padding-top: 20px;

}

.back {
    width: 50px;
    height: auto;
}

.editcategorybtn button {
    padding: 5px 269px 5px 200px;
    background-color: #004AAD;
    cursor: pointer;
    font-weight: 500;
    border-radius: 5px;
    color:white
}

/* CSS stylesheet */
.addIcon {
    border-color: #9BA3A2;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border-width: 1px;
    padding: 4px;
}

.addMissingIcon {
    border-color: #b8251c;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border-width: 1px;
    padding: 4px;
}

.checkBox {
    align-content: center;
    display: flex;
    flex-direction: row;
    width: 364px;
}

.updateForAll {
    margin-left: 10px;
    align-self: center;
    color: #606060;
}

.messageText {
    color: #ab2626;
    /*margin-left: 32px;*/
    margin-bottom: 5px;
}

/*@media (max-width: 450px)  {*/
/*    .editaddcategory {*/
/*        width: 90px;*/
/*        margin: 12px 52px;*/
/*        border-radius: 9px;*/
/*        height: 75px;*/
/*        background-color: #fbfbfb1f;*/
/*        display: flex;*/
/*        border: 1px solid #004AAD;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*        cursor: pointer;*/
/*    }*/
/*}*/

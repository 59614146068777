* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.privacyboundry {
    /* margin: 80px 231px 0px 231px; */
    text-align: justify;
    /* width: 450px;
    display: flex;
    align-items: center;
    justify-content: center; */
}

.bottomSpace {
    height: 350px;
}

.header {
    /* background-color: aqua; */
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 100px;
    justify-content: space-between;
    padding-bottom: 100px;
    overflow: hidden;
}

.header h3 {
    font-size: 25px;
}

.privacyBtn {
    background-color: #ffff;
    color: #000;
    border: 1px solid #000;
    width: 200px;
    border-radius: 10px;
}

.privacyBtn:hover {
    background-color: #d0d0d0;
}

.privacyboundry h1 {
    font-size: 48px;
}

.privacyboundry p {
    font-size: 20px;
    font-weight: 100;
    padding-top: 10px;
}

.privacyContent {
    padding-top: 30px;
    line-height: 30px;
}

.privacyContent .break {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.miniheadlines {
    font-size: 20px;
}

@media (max-width: 1920px) {
    .privacyboundry {
        margin: 80px 231px 0px 231px;
        text-align: justify;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding-top: 100px;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    .header h3 {
        font-size: 25px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 200px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 48px;
    }

    .privacyboundry p {
        font-size: 20px;
        font-weight: 100;
        padding-top: 10px;
    }

    .privacyContent {
        padding-top: 30px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 20px;
    }
}

@media (max-width: 1500px) {
    .privacyboundry {
        margin: 80px 231px 0px 231px;
        text-align: justify;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding-top: 100px;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    .header h3 {
        font-size: 25px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 200px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 48px;
    }

    .privacyboundry p {
        font-size: 20px;
        font-weight: 100;
        padding-top: 10px;
    }

    .privacyContent {
        padding-top: 30px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .privacyboundry {
        margin: 80px 231px 0px 231px;
        text-align: justify;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding-top: 100px;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    .header h3 {
        font-size: 25px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 200px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 48px;
    }

    .privacyboundry p {
        font-size: 20px;
        font-weight: 100;
        padding-top: 10px;
    }

    .privacyContent {
        padding-top: 30px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 20px;
    }
}

@media (max-width: 900px) {
    .privacyboundry {
        margin: 80px 231px 0px 231px;
        text-align: justify;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding-top: 100px;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    .header h3 {
        font-size: 25px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 200px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 48px;
    }

    .privacyboundry p {
        font-size: 20px;
        font-weight: 100;
        padding-top: 10px;
    }

    .privacyContent {
        padding-top: 30px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .privacyboundry {
        margin: 80px 231px 0px 231px;
        text-align: justify;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        padding-top: 100px;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    .header h3 {
        font-size: 25px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 200px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 48px;
    }

    .privacyboundry p {
        font-size: 18px;
        font-weight: 100;
        padding-top: 10px;
    }

    .privacyContent {
        padding-top: 30px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 20px;
    }
}

@media (max-width: 450px) {
    .privacyboundry {
        text-align: justify;
        width: 450px;
        margin: 0px 5px 20px 5px;
        /* width: 450px;
        display: flex;
        align-items: center;
        justify-content: center; */
    }

    .bottomSpace {
        height: 350px;
    }

    .header {
        /* background-color: aqua; */
        width: 100%;
        height: 30px;
        /* width: 450px; */
        display: flex;
        align-items: center;
        padding-top: 92px;
        justify-content: center;
        gap: 63px;
        padding-right: 35px;
        padding-bottom: 95px;
    }

    .header h3 {
        font-size: 20px;
    }

    .privacyBtn {
        background-color: #ffff;
        color: #000;
        border: 1px solid #000;
        width: 139px;
        padding: 8px 5px 8px 5px;
        font-size: 14px;
        border-radius: 10px;
    }

    .privacyBtn:hover {
        background-color: #d0d0d0;
    }

    .privacyboundry h1 {
        font-size: 35px;
        padding-right: 59px;
        text-align: center;
    }

    .privacyboundry p {
        font-size: 20px;
        font-weight: 300;
        padding-top: 10px;
    }

    .privacyContent {
        width: 450px;
        /* padding-top: 30px; */
        padding: 10px 106px 10px 40px;
        line-height: 30px;
    }

    .privacyContent .break {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .miniheadlines {
        font-size: 18px;
    }
}

.editcard {
    background: white;
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    z-index: 1000;
}


.editmodal {
    position: fixed;
    top: 12vh;
    left: 30%;
    width: 37%;
    height: 80vh;
    z-index: 1000;
    overflow-y: scroll;
}

@media (max-height: 800px) {
    .editmodal {
        position: fixed;
        top: 12vh;
        left: 30%;
        width: 37%;
        height: 85vh;
        z-index: 1000;
        overflow-y: scroll;
    }
}

/*@media (max-width: 450px)  {*/
/*    .editmodal {*/
/*        position: fixed;*/
/*        top: 12vh;*/
/*        left: 10%;*/
/*        width: 120%;*/
/*        height: 662px;*/
/*        z-index: 1000;*/
/*        overflow-y: scroll;*/
/*    }*/
/*}*/

/* <div className="popup-container-welcome">
<div className="popup-Delete-welcome">
    <p>{message}</p>
    <div className="popup-buttons-welcome">
        <button style={{
            backgroundColor:'#004aad'
        }} onClick={onCancel}>OK</button>
    </div>
</div>
</div> */

.popup-container-welcome {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.popup-Delete-welcome {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    height: 100% !important;
    max-width: 300px;
    max-height: 150px;
    text-align: center;
}

.popup-Delete-welcome p {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    height: 100% !important;
    object-fit: contain;
}

.popup-buttons-welcome button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #004aad;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 10px;
}
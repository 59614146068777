.demo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.demo-overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 400px;
  border-radius: 10px;
}

.demo-overlay-content p {
  text-align: center;
  font-size: 17px;
  color: #424242;
  margin-top: 15px;
}

.demo-overlay-content h1 {
  text-align: center;
  /* font-size: 17px; */
  color: #004AAD;
  margin-top: 15px;
}

.demo-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  vertical-align: middle;
}

.demo-overlay-content a {
  text-decoration: none;
  color: #004AAD;
  font-style: italic;
  transform: skewX(-5deg);
}

.demo-overlay-content button {
  background-color: #004AAD;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  min-width: 140px;
  margin-top: 20px;
  font-size: 15px;
}

.demo-popup-header svg {
  color: #20292f;
  /* display: none; */
}
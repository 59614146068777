.feedback-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 100vh;*/
    margin-top: 80px;

}

.feedback-form {
    width: 100%;
    max-width: 1000px;
    /* max-height: 100px; */
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    text-align: center;
    /* padding-left: 50px; */
}

.feedback-form h1 {
    /* padding-right: 100px; */
    /* flex: 1; */
    /*padding-top: 0%;*/
    padding-bottom: 30px;
    color: #004AAD;
}

.feedback-textbox {
    /* flex: 1; */
    width: 100%;
    padding: 10px;
    margin-top: 20x;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px; /* Adjust the font size as needed */
    resize: vertical;
    height: 400px;
    width: 600px;
}


.submit-feedback-btn {
    padding: 10px 20px;
    background-color: #004AAD;
    color: white;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: block;
    width: fit-content;
    margin: 20px auto 0;

}
